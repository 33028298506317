.rate-features {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 47px var(--p-30) 50px var(--p-30);
  max-width: 390px;
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
  background-color: var(--white);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--light-blue-grey);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &_good {
    &::before {
      background-color: var(--butterscotch);
    }
  }
  &_average {
    &::before {
      background-color: var(--soft-green);
    }
  }
  &__top {
    margin-bottom: 20px;
  }
  &__list {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
  &__line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px;
    margin-right: calc(var(--m-30) - 20px);
    margin-left: auto;
    width: calc(100% - 65px - 30px - 55px);
    height: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #f2f4fa;

    &_good {
      background-color: var(--pale);
      .rate-features__line-inner {
        background-color: var(--butterscotch);
      }
    }
    &_average {
      background-color: var(--ice);
      .rate-features__line-inner {
        background-color: var(--soft-green);
      }
    }
  }
  &__line-inner {
    width: 176px;
    height: 6px;
    border-radius: 5px;
    background-color: var(--light-blue-grey);
  }
  &__text {
    min-width: 100px;
    flex-shrink: 0;
  }
  &__score {
    min-width: 40px;
    flex-shrink: 0;
    text-align: right;
  }
}