.notification-small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-15) var(--p-30);
  min-height: 60px;
  border-radius: 3px;
  &_ok {
    background-color: #f7fcfc;
    .notification-small__text {
      color: var(--soft-green);
    }
    .notification-small__icon {
      background-color: var(--soft-green);
    }
  }
  &_error {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 18px;
    background-color: #fff4f3;
    .notification-small__text {
      color: var(--coral);
    }
    .notification-small__icon {
      background-color: var(--coral);
    }
  }
  &_waiting {
    background-color: #fff9ed;
    .notification-small__text {
      color: var(--butterscotch);
    }
    .notification-small__icon {
      background-color: var(--butterscotch);
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    color: var(--white);
  }
  &__text {
    position: relative;
    padding-left: 45px;
  }
}