.image-file {
  display: flex;
  align-items: center;
  &__img {
    margin-right: var(--m-30);
    width: 45px;
    height: 45px;
    background: no-repeat center;
    background-size: cover;
    border-radius: var(--border-radius);
    overflow: hidden;
  }
}