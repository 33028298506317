.confirmations {
  .icon {
    margin-right: var(--m-30);
    text-shadow: 0 10px 10px rgba(95, 202, 114, 0.3);
    color: var(--soft-green);
  }
  &__title {
    margin-bottom: 33px;
    @include breakpoint-md {
      text-align: center;
    }
  }
  &__text {
    margin-bottom: 60px;
  }
  &__container {
    margin: -20px auto auto -60px;
    width: calc(100% + 120px);
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    @include breakpoint-md {
      margin-bottom: var(--m-60);
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__copyright {
    margin-top: 45px;
    text-align: right;
  }
  img {
    width: 100%;
  }
}