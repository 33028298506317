.popup.popup_choose-factory {
  max-width: 600px;
  min-height: 660px;
}
.choose-factory {
  &__title {
    margin-bottom: 20px;
  }
  &__search {
    margin-bottom: 22px;
    width: 100%;
  }
  &__title-small {
    margin-bottom: 9px;
  }
}