.header-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--p-30);
  margin-bottom: var(--m-60);
  .logo {
    display: inline-flex;
    align-items: center;
    margin-right: var(--m-60);
    font-size: 60px;
    color: var(--soft-green);
    text-shadow: 0 10px 10px rgba(95, 202, 114, 0.3);
    text-decoration: none;
    span {
      margin-left: var(--m-30);
      text-shadow: none;
    }
  }
}