.alert {
  display: flex;
  align-items: center;
  padding: var(--p-6) var(--p-30);
  height: 60px;
  border-radius: 3px;
  background-color: var(--coral);
  color: var(--white);
  .icon {
    margin-right: 37px;
  }
  &__text {
    color: var(--white);
  }
}