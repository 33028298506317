.deals {
  display: flex;
  flex-direction: column;
}
.deals__top-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .deals__title-small {
    margin-bottom: 0;
  }
}
.deals__row {
  margin-right: -7px;
  margin-left: -7px;
}
.deals__col {
  padding-left: 7px;
  padding-right: 7px;
}
.deals__row-2 {
  margin-right: -3px;
  margin-left: -3px;
}
.deals__col-2 {
  display: flex;
  padding-left: 3px;
  padding-right: 3px;
  > * {
    flex-grow: 1;
  }
}
.deals__title-small {
  margin-right: var(--m-15);
  margin-bottom: var(--m-15);
  text-transform: uppercase;
}
.deals__text {
  color: #aaaebf;
  text-align: center;
}
.deals__wrap-no {
  text-align: center;
  padding: var(--p-15) var(--p-15) calc(var(--p-30) + var(--p-15)) var(--p-15);
}
.deals__item {
  &:not(:last-of-type) {
    margin-bottom: var(--m-6);
  }
}
.deals__btn {
  padding: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  &.open {
    .deals__arrow-open {
      display: block;
    }
    .deals__arrow-close {
      display: none;
    }
  }
  .deals__arrow-open {
    display: none;
  }
}