.offers-catalog {
  padding: 54px var(--p-60) var(--p-60) var(--p-60);
  background-color: var(--white);

  &__title {
    margin-bottom: 16px;
  }

  &__table {
    width: 100%;
    display: table;
  }

  &__head {
    height: 45px;
    border-radius: 3px;
    background-color: var(--pale-grey);
  }

  &__head &__td {
    @include text-caption-l;
    padding: 0 var(--p-15);
    vertical-align: middle;
    text-align: left;
    color: var(--light-blue-grey);
  }

  &__tr,
  &__head,
  &__content {
    display: table-row;
  }

  &__td {
    display: table-cell;

    &.js-sort {
      cursor: pointer;
      user-select: none;

      &.asc .icon-arrow-down {
        color: var(--beige);
      }

      &.desc .icon-arrow-up {
        color: var(--beige);
      }
    }
  }

  &__content &__td {
    padding: 0 var(--p-15);
    height: 90px;
    vertical-align: middle;
    text-align: left;
    border-bottom: dashed 1px var(--light-blue-grey);

    &:first-of-type {
      min-width: 348px;
    }
  }

  .icon-pin {
    margin-right: var(--m-15);
    color: var(--dark);
  }

  &__down,
  &__up {
    position: absolute;
    top: 50%;
    left: calc(100% + var(--m-6));
    transform: translateY(-50%);
  }

  &__up {
    margin-top: -6px;
  }

  &__down {
    margin-top: 3px;
  }

  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: no-repeat center;
    background-size: contain;
  }
}