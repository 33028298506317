.title {
  @include title;
  margin-bottom: 46px;
}
.title-small {
  @include title-small;
}
.text-body-s {
  @include text-body-s;
}
.text-body-m {
 @include text-body-m;
}
.text-body-mb {
  @include text-body-m;
  font-weight: bold;
}
.text-body-l {
  @include text-body-l;
}
.text-body-i {
  @include text-body-m;
  font-style: italic;
}
.text-body-si {
  @include text-body-s;
  font-style: italic;
}
.text-body-sb {
  @include text-body-s;
  font-weight: bold;
}
.text-caption-m {
  @include text-caption-m;
}
.text-caption-l {
  @include text-caption-l;
}
.text-muted {
  color: #aaaebf;
}