.drop {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: var(--ice);
  border-radius: var(--border-radius);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23B8D7D2FF' stroke-width='2' stroke-dasharray='2%2c 7' stroke-dashoffset='4' stroke-linecap='round'/%3e%3c/svg%3e");
  cursor: pointer;
}
.drop_drag-enter {
  background-color: rgba(81, 142, 248, 0.07);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23518EF8FF' stroke-width='2' stroke-dasharray='2%2c 7' stroke-dashoffset='4' stroke-linecap='round'/%3e%3c/svg%3e");
}
.drop__icon {
  margin-right: 8px;
}
.drop__text {
  color: #aaaebf;
  pointer-events: none;
}
.drop__text-drag {
  text-transform: uppercase;
  color: var(--soft-blue);
  pointer-events: none;

}