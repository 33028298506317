.deals-item-factory {
  padding: 9px 14px var(--p-15) var(--p-15);
  border-radius: 3px;
  background-color: var(--pale-grey-two);
  &_ok {
    .deals-item-factory__icon {
      background-color: var(--soft-green);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-factory__text {
      color: var(--soft-green);
    }
  }
  &_waiting {
    .deals-item-factory__icon {
      background-color: var(--butterscotch);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-factory__text {
      color: var(--butterscotch);
    }
  }
  &_error {
    .deals-item-factory__icon {
      background-color: var(--coral);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-factory__text {
      color: var(--coral);
    }
  }
  &_3 {
    background-color: #f2faf9;
  }
  &_4 {
    background-color: #fff6e6;
  }

}
.deals-item-factory__icon {
  text-align: center;
  width: 30px;
  height: 30px;
  margin: 0 9px 0 0;
  border-radius: 50%;
  flex-shrink: 0;
  .icon {
    line-height: 30px;
  }
}