.popup.popup_choose-offer {
  max-width: 600px;
  padding-bottom: 55px;
}
.choose-offer {
  &__title {
    margin-bottom: 50px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px var(--p-30) 14px var(--p-15);
    margin-bottom: var(--m-6);
    border-radius: 3px;
    background-color: #f7fcfc;
    &:hover {
      background-color: var(--pale);
      .choose-offer__btn {
        opacity: 1;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    padding-right: var(--m-15);
    text-align: left;
  }
  &__btn {
    align-self: center;
    display: flex;
    opacity: 0;
  }
  &__link-to-create {
    display: flex;
    align-items: center;
    padding: var(--p-15) var(--p-30);
    min-height: 75px;
    border-radius: 3px;
    border: dashed 1px #dbebe8;
    background-color: #f7fcfc;
  }
  &__link-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 47px;
  }
}