.chat-show {
  display: flex;
  flex-direction: column;
  > .container-flex {
    max-height: calc(100vh - 130px);
    min-height: 400px;
  }
  .chat-list-item {
    &.active {
      background-color: var(--beige);

      .chat-list-item__statistic-text {
        color: var(--dark);
      }
    }
  }
}

.chat-list-item {
  display: flex;
  padding: 13px var(--p-30);
  min-height: 75px;
  background-color: var(--white);

  &.unread {
    background-color: #fff9ed;

    .chat-list-item__message {
      font-weight: bold;
    }
  }

  &:hover {
    background-color: var(--pale);
  }

  &__left {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
  }

  &__avatar {
    position: relative;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background: var(--pale-grey) no-repeat center;
    border-radius: 50%;
    background-size: cover;
  }

  &__avatar-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color: var(--coral);
    border-radius: 50%;
  }

  &__right {
    margin-right: 10px;
    width: 240px;
    overflow-wrap: break-word;
  }

  .chat-list-item__statistic-wrap {
    margin-left: auto;
  }

  .chat-list-item__statistic-text {
    color: var(--light-blue-grey);
  }
}

.chat-list-item__name {
  margin-bottom: -2px;
}

.chat-list-item__icon-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 50%;
  background: no-repeat center / contain;
}

.chat-list-item__img-wrap {
  width: 45px;
  height: 45px;
  position: relative;
  flex-shrink: 0;

  &.block {
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../images/notification/stop.svg") no-repeat center / cover;
    }
  }

  &.grey {
    background-color: var(--pale-grey);
  }

  &.round {
    border-radius: 50%;
  }
}

.chat-list-item__img-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--white);

  &.good {
    background-color: var(--soft-green);
  }

  &.bad {
    background-color: var(--coral);
  }

  &.round {
    border-radius: 50%;
  }

  &.gray {
    background-color: #aaaebf;
  }

  &.butterscotch {
    background-color: var(--white);

    .icon {
      color: var(--butterscotch);
    }
  }

  &.purple {
    background-color: #ff7ab6;
  }

  &.edit {
    background-color: var(--white);

    .icon {
      color: #aaaebf;
    }
  }
}

.chat-list-item__icon {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;

  .icon {
    line-height: 45px;
    color: var(--white);
  }

  &.green {
    background-color: var(--soft-green);
  }

  &.bad {
    background-color: var(--coral);
  }

  &.grey {
    background-color: #aaaebf;
  }

  &.round {
    border-radius: 50%;
  }
}

.chat-list-item__inner {
  display: flex;
  width: 100%;
}

.chat-list-item__pined-wrap {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  padding: 15px 10px;

  .icon {
    color: #aaaebf;
  }
}