.main-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 52px var(--p-60) 54px var(--p-60);
  &__list {
    display: flex;
    align-items: center;
    li {
      &:not(:last-of-type) {
        margin-right: var(--m-60);
      }
    }
  }
}