.coming-soon {
  display: flex;
  flex-direction: column;
  padding: var(--p-60) var(--p-60) 25px var(--p-60);
  border-radius: 6px;
  background-color: var(--white);
  min-height: calc(100vh - var(--p-30));
  box-sizing: border-box;
  max-width: 1380px;
  margin: 0 auto;
  @include breakpoint-md {
    padding: 10px;
  }
  &__header {
    display: flex;
    align-items: center;
    @include breakpoint-md {
      justify-content: center;
      padding: var(--p-30) 0;
    }
  }
  .icon {
    margin-right: var(--m-30);
    text-shadow: 0 10px 10px rgba(95, 202, 114, 0.3);
    color: var(--soft-green);
  }
  &__title {
    margin-bottom: 33px;
    @include breakpoint-md {
      text-align: center;
    }
  }
  &__text {
    margin-bottom: 22px;
  }
  &__container {
    margin: -20px auto auto -60px;
    width: calc(100% + 120px);
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    @include breakpoint-md {
      margin-bottom: var(--m-60);
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__copyright {
    margin-top: 45px;
    text-align: right;
  }
  img {
    width: 100%;
  }
}
.coming-soon__logo {
  display: block;
  z-index: 2;
}
.input__completed {
  input[type="email"] {
    border-color: var(--soft-green) !important;
  }
}
