.rtl {
  direction: rtl
}

.vdp-datepicker {
  position: relative;
  text-align: left
}

.vdp-datepicker * {
  box-sizing: border-box
}

.vdp-datepicker__calendar {
  position: absolute;
  z-index: 100;
  background: #fff;
  width: 300px;
  border: 1px solid #ccc
}

.vdp-datepicker__calendar header {
  display: block;
  line-height: 40px
}

.vdp-datepicker__calendar header span {
  display: inline-block;
  text-align: center;
  width: 71.42857142857143%;
  float: left
}

.vdp-datepicker__calendar header .next, .vdp-datepicker__calendar header .prev {
  width: 14.285714285714286%;
  float: left;
  text-indent: -10000px;
  position: relative
}

.vdp-datepicker__calendar header .next:after, .vdp-datepicker__calendar header .prev:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 6px solid transparent
}

.vdp-datepicker__calendar header .prev:after {
  border-right: 10px solid #000;
  margin-left: -5px
}

.vdp-datepicker__calendar header .prev.disabled:after {
  border-right: 10px solid #ddd
}

.vdp-datepicker__calendar header .next:after {
  border-left: 10px solid #000;
  margin-left: 5px
}

.vdp-datepicker__calendar header .next.disabled:after {
  border-left: 10px solid #ddd
}

.vdp-datepicker__calendar header .next:not(.disabled), .vdp-datepicker__calendar header .prev:not(.disabled), .vdp-datepicker__calendar header .up:not(.disabled) {
  cursor: pointer
}

.vdp-datepicker__calendar header .next:not(.disabled):hover, .vdp-datepicker__calendar header .prev:not(.disabled):hover, .vdp-datepicker__calendar header .up:not(.disabled):hover {
  background: #eee
}

.vdp-datepicker__calendar .disabled {
  color: #ddd;
  cursor: default
}

.vdp-datepicker__calendar .flex-rtl {
  display: flex;
  width: inherit;
  flex-wrap: wrap
}

.vdp-datepicker__calendar .cell {
  display: inline-block;
  padding: 0 5px;
  width: 14.285714285714286%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year {
  cursor: pointer
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #4bd
}

.vdp-datepicker__calendar .cell.selected {
  background: #4bd
}

.vdp-datepicker__calendar .cell.selected:hover {
  background: #4bd
}

.vdp-datepicker__calendar .cell.selected.highlighted {
  background: #4bd
}

.vdp-datepicker__calendar .cell.highlighted {
  background: #cae5ed
}

.vdp-datepicker__calendar .cell.highlighted.disabled {
  color: #a3a3a3
}

.vdp-datepicker__calendar .cell.grey {
  color: #888
}

.vdp-datepicker__calendar .cell.grey:hover {
  background: inherit
}

.vdp-datepicker__calendar .cell.day-header {
  font-size: 75%;
  white-space: nowrap;
  cursor: inherit
}

.vdp-datepicker__calendar .cell.day-header:hover {
  background: inherit
}

.vdp-datepicker__calendar .month, .vdp-datepicker__calendar .year {
  width: 33.333%
}

.vdp-datepicker__calendar-button, .vdp-datepicker__clear-button {
  cursor: pointer;
  font-style: normal
}

.vdp-datepicker__calendar-button.disabled, .vdp-datepicker__clear-button.disabled {
  color: #999;
  cursor: default
}

.vdp-datepicker{
  input {
    @include text-body-m;
    display: flex;
    align-items: center;
    padding: 0 var(--p-30);
    width: 100%;
    height: 60px;
    border-radius: var(--border-radius);
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
  }
  .icon {
    position: absolute;
    top: 50%;
    right: var(--p-30);
    transform: translateY(-50%);
    color: var(--light-blue-grey);
  }
  .icon-remove {
    right: 80px;
  }
}
