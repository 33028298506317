.chat__wrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - 465px - 30px);
}
.chat__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 90px;
  margin-left: calc(-1 * var(--m-30));
  margin-right: calc(-1 * var(--m-30));
  > * {
    overflow: hidden;
  }
}
.chat__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 665px;
  .rectangle {
    position: relative;
    margin-bottom: 45px;
  }
}
.chat__list-link {
  margin-left: var(--m-30);
}
.chat__chat-wrap {
  max-height: 100%;
  overflow-y: auto;
  padding-right: var(--p-60);
}
.chat__date {
  margin-bottom: 25px;
  color: var(--light-grey-blue);
  text-transform: uppercase;
  text-align: center;
}
.chat__messages-wrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat__with-badge {
  padding-top: 28px;
  .badge {
    transform: translate(-50%, 0);
  }
}
.chat__item-top {
  margin-bottom: 35px;
}
.chat-logo {
  display: flex;
}
.chat-logo__left {
  margin-right: 15px;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.chat__title-empty {
  position: relative;
}
.chat__with-line {
  &:before {
    top: 60px;
    left: 0;
  }
}
.chat__input-message {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  textarea {
    border-radius: 30px;
    padding-right: 80px;
    height: 60px;
    min-height: 60px;
    max-height: 300px;
    overflow-y:hidden;
    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 0;
      border-radius: 0;
      outline: none;
      -webkit-box-shadow: none;
    }
  }
  .chat__btn-send {
    position: absolute;
    bottom: 0;
    right: 0;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: transparent;
      background-color: transparent;
      i {
        margin: 0;
      }
    }
    &:hover,
    &:focus {
      outline: none;
      &:before {
        content: none;
      }
      span {
        border: transparent;
        background-color: transparent;
        box-shadow: none;
        i {
          color: var(--soft-blue);
        }
      }
    }
  }
}