.offer {
  margin-bottom: 22px;
  &__top {
    &_last-message {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .offer__top__btn {
        display: block;
      }
      .offer__top__title {
        margin-bottom: 8px;
      }
      .offer__top__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__top__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__top__title {
    margin-bottom: 0;
  }
  &__top__btn {
    display: none;
  }
  &__list {
    display: flex;
    align-items: center;
  }

  &__create {
    color: var(--light-blue-grey);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    min-height: 60px;
    height: 50px;
    padding-left: calc(var(--p-60) + var(--p-30));
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 60px;
      height: 60px;
      transform: translateY(-50%);
      border-radius: 6px;
      background-color: var(--dark);
    }
    &_small {
      min-height: 45px;
      height: 40px;
      padding-left: calc(45px + var(--p-30));
      &::before {
        width: 45px;
        height: 45px;
      }
    }
  }
  &__title-small {
    margin-top: 9px;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: var(--light-blue-grey);
  }

  &__link {
    @include link;
    @include text-body-m;
  }

  &__tag {
    display: inline-flex;
    align-items: center;
    padding: 0 var(--p-15);
    height: 28px;
    background-color: var(--pale-grey);
  }
}