.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  &__li {
    position: relative;
    padding-left: 51px;
    color: var(--light-blue-grey);
    white-space: nowrap;
    &:not(:last-of-type) {
      margin-right: var(--m-30);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: var(--white);
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(20px / 2);
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: var(--pale-grey);
      border-radius: 50%;
    }

    &_active {
      color: var(--butterscotch);
      &::before {
        background-color: var(--pale);
      }
      &::after {
        background-color: var(--butterscotch);
      }
    }
    &_completed {
      color: var(--soft-green);
      &::before {
        background-color: var(--ice);
      }
      &::after {
        background-color: var(--soft-green);
      }
    }
  }
  &__line {
    margin-right: var(--p-30);
    flex-basis: 30px;
    height: 1px;
    background-color: var(--pale-grey);
  }
}