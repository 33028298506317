.rate-small2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-15);
  min-width: 52px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: var(--light-blue-grey);
  &_good {
    background-color: var(--butterscotch);
  }
  &_average {
    background-color: var(--soft-green);
  }
  &__text {
    color: var(--white);
  }
}