:root {
  --black: #000;
  --dark: #110f21;
  --dark_rgb: 17, 15, 33;
  --light-blue-grey: #bcc0d4;
  --white: #fff;
  --soft-green: #5fca72;
  --soft-green_rgb: 95, 202, 114;
  --butterscotch: #ffc544;
  --ice: #e7f8f5;
  --pale: #fff0d4;
  --beige: #f7d09f;
  --pale-grey: #edeff5;
  --soft-blue: #518ef8;
  --soft-blue_rgb: 81, 142, 248;
  --light-grey-blue: #aaaebf;
  --pale-grey-two: #f7f9ff;
  --coral: #ff4f3f;
  --btn-hover-before: #b5c662;
  --btn-hover-after: #efc350;
  --breakpoint-xs: 0;
  --x: 30px;
  --p-120: calc(4 * var(--x)); //120
  --p-90: calc(3 * var(--x)); //90
  --p-60: calc(2 * var(--x)); //60
  --p-45: calc(1.5 * var(--x)); //45
  --p-30: calc(var(--x));     //30
  --p-15: calc(var(--x) / 2); //15
  --p-6: calc(var(--x) / 5); //6
  --m-120: calc(4 * var(--x)); //120
  --m-60: calc(2 * var(--x)); //60
  --m-30: calc(var(--x));     //30
  --m-15: calc(var(--x) / 2); //15
  --m-6: calc(var(--x) / 5); //6
  --font-family-sans-serif: 'Open Sans', sans-serif;
  --font-family-source-sans-pro: 'Source Sans Pro', sans-serif;
  --font-family-prata: 'Prata', serif;
  --animation-btn-time: .3s;
  --border-radius: 6px;
}

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;