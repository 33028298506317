.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade2-enter-active {
  transition: opacity .2s;
}

.fade2-enter, .fade2-leave-to {
  opacity: 0;
}