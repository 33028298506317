.upload-image {

  .drop-files__icon {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    &:before {
      font-size: 60px;
    }
  }
  .drop-files__text {
    padding-left: 0;
    padding-top: calc(var(--p-60) + 16px);
    @include text-body-m;
    color: #b5b9cc;
  }
}