.score {
  @include text-caption-l;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 20px;
  border-radius: 4px;
  color: var(--white);
  &_good {
    background-color: var(--butterscotch);
  }
}