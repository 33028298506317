fieldset[disabled] .multiselect {
  pointer-events: none
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block
}

.multiselect__spinner:after, .multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent
}

.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(.41, .26, .2, .62);
  animation-iteration-count: infinite
}

.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(.51, .09, .21, .8);
  animation-iteration-count: infinite
}

.multiselect__loading-enter-active, .multiselect__loading-leave-active {
  transition: opacity .4s ease-in-out;
  opacity: 1
}

.multiselect__loading-enter, .multiselect__loading-leave-active {
  opacity: 0
}

.multiselect, .multiselect__input, .multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e
}

.multiselect * {
  box-sizing: border-box
}

.multiselect:focus {
  outline: none
}

.multiselect--disabled {
  opacity: .6
}

.multiselect--active {
  z-index: 50
}

.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active.multiselect--multiple .multiselect__input{
  width: auto !important;
}

.multiselect--active .multiselect__select {
  transform: rotate(180deg)
}

.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.multiselect__input, .multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  //margin-bottom: 8px;
  margin-top: 3px;
  vertical-align: top
}

.multiselect__input:-ms-input-placeholder {
  color: #35495e
}

.multiselect__input::placeholder {
  font-size: 0;
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input, .multiselect__tag ~ .multiselect__single {
  width: auto
}

.multiselect__input:hover, .multiselect__single:hover {
  border-color: #cfcfcf
}

.multiselect__input:focus, .multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none
}

.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px
}

.multiselect__tags-wrap {
  display: inline
}

.multiselect__tags {
  min-height: 60px;
  display: block;
  padding: var(--p-15) var(--p-30) 9px var(--p-30);
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px
}

.multiselect__tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 4px 33px 4px var(--p-15);
  border-radius: 3px;
  margin-right: 10px;
  @include text-caption-m;
  color: var(--dark);
  line-height: 1;
  background-color: var(--pale);
  margin-bottom: var(--m-6);
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  min-height: 28px;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.multiselect__tag-icon {
  cursor: pointer;
  position: absolute;
  right: var(--p-15);
  top: 50%;
  font-weight: 700;
  font-style: initial;
  transform: translateY(-50%);
  padding: 2px;
  width: 16px;
  height: 16px;
  text-align: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.multiselect__tag-icon:before {
  content: "\e827";
  color: var(--beige);
  font-size: 12px;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8
}

.multiselect__current, .multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer
}

.multiselect__select {
  display: none;
}

.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: ""
}

.multiselect__placeholder {
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 1.4px;
  color: #b5b9cc;
}

.multiselect--active .multiselect__placeholder {
  display: none
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  //border: 1px solid #e8e8e8;
  //border-top: none;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  border-top-radius-right: 0;
  border-top-radius-left: 0;
  box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8
}

.multiselect__content::webkit-scrollbar {
  display: none
}

.multiselect__element {
  display: block
}

.multiselect__option {
  display: block;
  padding: 12px var(--p-30);
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap
}

.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px
}

.multiselect__option--highlight,
.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight {
  background-color: var(--ice);
  outline: none;
}

.multiselect--disabled {
  background: #ededed;
  pointer-events: none
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6
}

.multiselect__option--disabled {
  cursor: text;
  pointer-events: none
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff
}

.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff
}

.multiselect-enter-active, .multiselect-leave-active {
  transition: all .15s ease
}

.multiselect-enter, .multiselect-leave-active {
  opacity: 0
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top
}

[dir=rtl] .multiselect {
  text-align: right
}

[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px
}

[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px
}

[dir=rtl] .multiselect__content {
  text-align: right
}

[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0
}

[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px
}

[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px
}

@keyframes spinning {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(2turn)
  }
}