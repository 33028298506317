@keyframes opacity {
  from { opacity: 0; }
  to { opacity: 1; }
}

.popup.popup-search {
  width: 100vw;
  max-width: 1410px;
  box-sizing: border-box;
  background-color: transparent;
  box-shadow: none;
  animation-duration: .8s;
  animation-name: opacity;
  padding-right: 140px;
}

.search {
  &__input {
    width: 100%;
    @include title-small;
    background: transparent;
    border: none;
    &::placeholder {
      color: var(--light-blue-grey);
    }
  }
  &__hints {
    display: flex;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 var(--p-6);
      height: 24px;
      border-radius: 3px;
      background-color: var(--white);
      cursor: pointer;
      user-select: none;
      &:not(:last-of-type) {
        margin-right: var(--m-6);
      }
    }
  }
}