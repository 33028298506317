.with-lines {
  position: relative;
  display: flex;
  align-items: center;
  padding: 42px var(--p-30) var(--p-30) var(--p-30);

  &::before {
    content: '\E821';
    position: absolute;
    top: 0;
    left: 0;
    width: 224px;
    height: 120px;
    font-size: 120px;
  }
  &_small {
    padding: var(--p-60) var(--p-30) 54px var(--p-60);
    &::before {
      top: var(--m-30);
      left: var(--m-30);
      width: 14px;
      height: 90px;
      font-size: 84px;
    }
  }
  &_big {
    padding: 0;
    &::before {
      top: var(--m-30);
      left: var(--m-30);
      width: 448px;
      height: 240px;
      font-size: 240px;
    }
  }
  &_green {
    &::before {
      color: #f2fdf4;
    }
    .with-lines__polygon {
      background-color: var(--soft-green);
    }
  }
  &_beige {
    &::before {
      color: #fff9ed;
    }
    .with-lines__polygon {
      background-color: var(--beige);
    }
  }
  &_butterscotch {
    padding: 0;
    &::before {
      content: none;
    }
    .with-lines__polygon {
      background-color:  var(--butterscotch);
    }
  }
  &_pale-grey-two {
    padding: 0;
    &::before {
      color: var(--pale-grey-two);
    }
  }
  &__polygon {
    position: relative;
    margin-right: var(--m-30);
    width: 90px;
    height: 90px;
    border-radius: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    &_small {
      margin-right: var(--m-15);
      width: 60px;
      height: 60px;
      align-self: flex-start;
      border-radius: 10px;
    }
  }
  &__text-wrap {
    position: relative;
  }
}
.with-lines__img {
  position: relative;
  margin-right: var(--m-15);
}