.toolbar {
  position: relative;
}

.toolbar__btn {
  border: none;
  background-color: transparent;
  color: var(--pale-grey);
  &:focus,
  &:hover {
    color: var(--dark);
  }
}

.toolbar__menu {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 15px 0;
  border-radius: 9px;
  box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
  background-color: var(--white);
  z-index: 1;
  li {
    margin-right: 0 !important;
    text-align: right;
  }
}