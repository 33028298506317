.form-validate {
  &:invalid {
    button[type="submit"] {
      pointer-events: none;

      &::after,
      &::before {
        content: none !important;
      }

      span {
        cursor: default !important;
        background-color: var(--pale-grey) !important;
        box-shadow: none !important;
        color: var(--white);

        &::after,
        &::before {
          content: none !important;
        }
      }
    }

  }
}