.popup-reset {
  position: absolute;
  top: calc(100% + 27px);
  left: 0;
  padding: var(--p-30);
  width: 100vw;
  max-width: 390px;
  box-sizing: border-box;
  box-shadow: 0 10px 10px 0 rgba(207, 222, 219, 0.2);
  background-color: var(--ice);
  z-index: 10;
  border-radius: var(--border-radius);

  &::before {
    content: '';
    position: absolute;
    top: -17px;
    left: 40px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 18px solid var(--ice);
  }

  &__text {
    margin-bottom: 9px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .input {
    margin-bottom: 0;
  }
  .error-message {
    top: 34px;
  }
}