.choose-role {
  padding: 115px var(--p-30) 114px var(--p-30);
  text-align: center;

  &__title {
    margin-bottom: var(--m-60);
  }

  &__wrap {
    display: flex;
    justify-content: center;
  }

  &__item {
    position: relative;
    display: flex;
    padding: 0 var(--p-60);
  }
  &__item__title {
    color: var(--dark);
  }
  &__text-wrap {
    position: relative;
    text-align: left;
  }

  &__icon-help {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--light-blue-grey);
  }

  &__text {
    margin-bottom: var(--m-15);
    color: var(--dark);
  }
}