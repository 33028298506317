.rate-small {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px var(--m-6);
  min-width: 55px;
  min-height: 26px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--light-blue-grey);
  &_good {
    background-color: var(--butterscotch);
  }
  &_average {
    background-color: var(--soft-green);
  }
  &__icon {
    margin-right: var(--m-6);
    font-size: 15px;
    color: var(--white);
  }
  &__text {
    color: var(--white);
    white-space: nowrap;
  }
}