.show-material {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left {
    display: flex;
    align-items: center;
  }
  &__img {
    margin-right: var(--m-30);
    width: 45px;
    height: 45px;
    background-size: cover;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: var(--dark) no-repeat center;
    background-size: contain;
  }
  &__link {
    cursor: pointer;
  }
}