.input-search {
  position: relative;
  display: inline-block;
  &__icon {
    position: absolute;
    top: 50%;
    left: var(--m-30);
    transform: translateY(-50%);
    color: var(--light-blue-grey);
    &_active {
      color: var(--light-blue-grey);
    }
  }

  input {
    padding: 2px var(--p-30) 2px var(--p-60);
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    @include text-body-m;
    color: var(--dark);
    &::placeholder {
      @include text-body-m;
      color: #b5b9cc;
    }
  }
}