.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__img-wrap {
    position: relative;
    //width: 390px;
    //height: 260px;
    margin-bottom: var(--m-15);
    border-radius: var(--border-radius);
    background-color: var(--dark);
    overflow: hidden;
    &.gallery_photo_no-load{
      height: 260px !important;
      width: 390px !important;

    }
  }
  &__icon {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: rgba(var(--dark_rgb), .2);
    cursor: pointer;
    font-size: 20px;
    &_favourite {
      top: 30px;
    }
    &_compare {
      top: 66px;
    }
    .icon {
      color: var(--white);
    }
  }
  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    margin-top: auto;
  }
  &__title {
    margin-right: var(--m-15);
  }
  &__text-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__geo {
    margin-right: var(--m-15);
    color: var(--dark);
  }

  &__text {
    margin-left: var(--m-15);
  }
}