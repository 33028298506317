.employers-add {
  &__text {
    margin-bottom: 21px;
  }
  .employer-item {
    &:not(:last-of-type) {
      margin-bottom: var(--m-15);
    }
  }
  &__btn {
    margin-top: 9px;
  }
}