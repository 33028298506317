.notification-block {
  width: 360px;
  border-radius: 8px;
  box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
  background-color: var(--white);
  &__top {
    padding: var(--p-15) var(--p-15) 0 var(--p-15);
    .dotted-line {
      margin-top: 9px;
    }
  }
  &__link {
    display: inline-block;
    padding: 1px 0 1px 0;
    margin-bottom: 4px;
    color: #aaaebf;
  }
  &__content {
    padding: 9px 0 var(--p-15) 0;
  }
  &__bottom {
    padding: 0 var(--p-15) 9px var(--p-15);
    .dotted-line {
      margin-bottom: 12px;
    }
  }
  &__link-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}