.active-offers-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-6) var(--p-30);
  min-height: 94px;
  height: 90px;
  opacity: 0.9;
  border-radius: 3px;
  background-color: var(--ice);
}