.rate-with-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: var(--p-30);
  max-width: 390px;
  width: 100%;
  min-height: 243px;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background-color: var(--pale-grey);
  &_good {
    background-color: var(--butterscotch);
  }
  &_average {
    background-color: var(--ice);
  }
  &__not-rate {
    min-height: 171px;
  }
  &__top {
    display: flex;
    align-items: baseline;
  }
  &__title {
    margin-right: auto;
  }
  .rate-icons .icon {
    font-size: 20px;
    color: var(--dark);
    &_disabled {
      opacity: 0.1;
    }
    &_not-rate {
      color: var(--light-blue-grey);
    }
  }
}
