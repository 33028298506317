.personal-info {
  margin-bottom: var(--m-15);
  padding: var(--p-60);
  border-radius: var(--border-radius);
  background-color: var(--white);
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: var(--m-60);
  }
  &__avatar-wrap {
    flex-shrink: 0;
    position: relative;
    margin-right: var(--m-60);
  }
  &__avatar {
    width: 239px;
    height: 239px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--pale-grey);
  }
  &__company-avatar {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: lightgray no-repeat center;
    background-size: cover;
  }
  &__desc {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }

  &__title {
    margin-bottom: 3px;
  }
  &__text {
    margin-bottom: 21px;
  }
  &__list {
    display: flex;
    li {
      display: flex;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: var(--m-60);
      }
      .icon {
        margin-right: 15px;
        line-height: 1;
      }
    }

  }

  &__right {
    margin-left: auto;
    text-align: right;
    &_align-start {
      align-self: flex-start;
    }
  }

  &__bio__title {
    margin-bottom: 8px;
  }
}