.link-route {
  position: relative;
  font-family: var(--font-family-source-sans-pro);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--soft-green);
  text-decoration: none;
  outline: none !important;
  &:hover {
    color: var(--soft-blue);
  }
  &:active {
    color: var(--dark);
  }
  &:focus {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-radius: 3px;
      border: dashed 1px var(--soft-green);
      background-color: var(--ice);
    }
    &::before {
      z-index: 1;
    }
    span {
      position: relative;
      z-index: 1;
    }
  }
  &_disabled {
    color: var(--pale-grey) !important;
    cursor: default !important;
    &::after {
      content: none !important;
    }
  }
  &_back {
    padding-left: 40px;
    &::before {
      font-size: 20px;
      content: '\e805';
      transform: scale(-1) translateY(50%);
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &_forward {
    padding-right: 35px;
    &::before {
      font-size: 20px;
      content: '\e805';
      transform: translateY(-50%);
      position: absolute;
      top: 50%;
      right: 0;
    }
  }
}

.link {
  @include link;
  &_big {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }
  &_norm {
    @include text-body-m;
  }
}
a.text-dashed:visited {
  color: inherit;
}