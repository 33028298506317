.range {
  display: flex;
  align-items: center;
  &_big {
    .range__input-wrap {
      width: 180px;
    }
    .range__icons_left {
      left: var(--m-30);
    }
    .range__icons_right {
      right: var(--m-30);
    }
  }
  &__title {
    margin-right: var(--m-30);
  }
  &__input-wrap {
    position: relative;
    margin-bottom: 0;
    margin-right: var(--m-30);
    width: 116px;
    box-sizing: border-box;
  }
  &__icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: var(--p-6);
    cursor: pointer;
    user-select: none;
    &_left {
      left: calc(var(--m-15) - var(--p-6));
    }
    &_right {
      right: calc(var(--m-15) - var(--p-6));
    }
  }
  &__input {
    text-align: center;
    &_passive {
      color: var(--light-blue-grey) !important;
    }
    &_active {
      background-color: var(--pale) !important;
      border-color: var(--pale) !important;
    }
  }
}