.chat-message {
  padding: 18px var(--p-30) 10px var(--p-30);
  overflow-wrap: break-word;
  width: 100%;
  color: var(--dark);
  border-radius: 20px;
  &:not(:last-of-type) {
    margin-bottom: var(--m-15);
  }
  &_your {
    background-color: #f5f6fc;
    border-bottom-left-radius: 0;
    align-self: flex-start;
  }
  &_partner {
    background-color: var(--pale-grey);
    border-bottom-right-radius: 0;
    align-self: flex-end;
    .chat-message__status {
      justify-content: flex-end;
    }
  }
}
.chat-message__time {
  color: var(--light-grey-blue);
}
.chat-message__status {
  display: flex;
  align-items: center;
}