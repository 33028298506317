.employer-person {
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: var(--m-30);
    .input {
      flex-grow: 1;
    }
  }
  &__logo {
    margin-right: 15px;
    width: 60px;
    height: 60px;
    background-color: var(--pale-grey);
    border-radius: 50%;
  }
}