.factory {
  padding: 25px var(--p-60) var(--p-60) var(--p-60);
  border-radius: var(--border-radius);
  background-color: var(--white);
  &__nav-scroll {
    margin-bottom: 9px;
  }
  .nav-scroll__inner_fixed +.dotted-line {
    padding-top: 131px;
  }
  .nav-scroll__inner_fixed {
    padding: 24px var(--p-30) 13px var(--p-30);
    .factory__btn-chart {
      //margin-top: 24px;
    }
    .factory__title-wrap {
      align-items: flex-start;
      margin-bottom: 0;
      min-height: 60px;
    }
    .factory__title {
      @include title-small;
    }
    .factory__list {
      margin-top: -30px;
    }
    .icon-pin {
      font-size: 16px;
    }
  }
  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 11px;
  }
  &__title {
    margin-bottom: 0;
    line-height: 1;
    padding-right: 50px;
    &_verified {
      span {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -5px;
          left: calc(10px + 100%);
          width: 32px;
          height: 32px;
          background: url('../images/icons/verified.svg') no-repeat center;
        }
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    .icon {
      margin-right: var(--m-15);
    }
  }

  &__container {
    padding: 0;
  }
  &__gallery {
    margin-bottom: 17px;
  }
}