.list {
  padding-top: 8px;
  &__title {
    margin-bottom: 38px;
  }
  &__list {
    li {
      position: relative;
      padding-left: calc(var(--p-15) + var(--p-6));
      &:not(:last-of-type) {
        margin-bottom: 4px;
      }
      &::before {
        content: '';
        position: absolute;
        top: calc(50% + 1px);
        left: 0;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background-color: var(--dark);
        border-radius: 50%;
      }
    }
  }
}