.input-rating {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px var(--p-60) 5px var(--p-30);
  height: 45px;
  border-radius: 6px;
  border: solid 1px var(--pale-grey);
  background-color: var(--white);

  &_checked {
    background-color: var(--pale);
  }

  &__icon-wrap {
    cursor: pointer;
    margin-right: var(--m-15);
  }

  &__icon {
    cursor: pointer;
    color: var(--light-blue-grey);
    padding: 0 3px;
    &:last-of-type {
      padding-right: 0;
    }
    &:first-of-type {
      padding-left: 0;
    }

    &_good {
      color: var(--butterscotch);
    }

    &_average {
      color: var(--soft-green);
    }

    &_bad {
      color: var(--dark);
    }
  }
  &__icon-close {
    position: absolute;
    top: 50%;
    right: var(--m-30);
    transform: translateY(-50%);
    padding: var(--p-6);
    margin-right: calc(var(--m-6) * -1);
    margin-left: var(--m-6);
    color: var(--beige);
    cursor: pointer;
  }
}