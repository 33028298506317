.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 44px;
    border-radius: 8px;
    background-color: #DADADA;
    outline: none;
    -webkit-box-shadow: none;
  }
}
