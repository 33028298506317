.profile {
  &__reviews {

  }
  &__reviews__title {

  }
  &__reviews__content {
    display: flex;
    justify-content: flex-start;
  }
}