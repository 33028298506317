.profile-small {
  position: relative;
  padding: 63px var(--p-60) var(--p-60) var(--p-60);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--light-blue-grey);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .good {
    &::before {
      background-color: var(--butterscotch);
    }
  }
  .average {
    &::before {
      background-color: var(--soft-green);
    }
  }
  &__avatar {
    margin-right: var(--m-15);
    width: 60px;
    height: 60px;
    background: no-repeat center;
    background-size: contain;
    border-radius: 50%;
  }
}