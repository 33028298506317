.drop-files {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 90px;
  border-radius: var(--border-radius);
  border: dashed 1px #dbebe8;
  background-color: #f7fcfc;
  cursor: pointer;
  &_small {
    padding: 0;
    .drop-files__text {
      padding-left: 0;
      padding-top: 26px;
    }
    .drop-files__icon {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &_drag-enter {
    border-radius: var(--border-radius);
    border: dashed 1px var(--soft-blue);
    background-color: rgba(81, 142, 248, 0.07);
    .drop-files__text {
      display: none;
    }
    .drop-files__drop-enter {
      display: block !important;
    }
  }
  &_error {
    padding: 5px var(--p-30);
    justify-content: space-between;
    height: 50px;
    border-radius: 3px;
    background-color: rgba(255, 79, 63, 0.07);
    border: none;
    .drop-files__error {
      display: flex !important;
    }
    .drop-files__drop-enter,
    .drop-files__text {
      display: none;
    }
  }
  &__text {
    position: relative;
    padding-left: 35px;
    color: var(--light-blue-grey);
    i {
      font-size: 20px;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  input[type="file"] {
    display: none;
  }
  &__label-to-drop {
    user-select: none;
    text-decoration: underline;
    cursor: pointer;
    text-transform: capitalize;
    span {
      text-transform: none;
    }
  }
  &__text-drop-enter {
    text-transform: uppercase;
    color: var(--soft-blue);
  }
  &__error {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  &__error__title {
    display: inline-flex;
    align-items: center;
    i {
      margin-right: var(--m-15);
      font-size: 20px;
      color: var(--coral);
    }
  }
  &__error__text {
    color: var(--coral);
  }
  &__link {
    @include link;
    @include text-body-m;
    margin-right: var(--p-15);
  }

  &__files {
    margin-top: 6px;
    padding: 9px var(--p-30) 11px var(--p-30);
    border-radius: 3px;
    background-color: var(--pale);
  }
  &__file {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: var(--m-6);
    }
  }
  &__size {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 25px;
  }
  &__icon-del {
    padding: 5px;
    cursor: pointer;
    color: var(--beige);
  }
}