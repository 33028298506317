.with-badge {
  position: relative;
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
  width: 20px;
  height: 20px;
  background-color: var(--coral);
  border-radius: 50%;
  font-family: var(--font-family-source-sans-pro);
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: var(--white);
}