.publish-btn {
  padding: var(--p-30) var(--p-60);
    &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: -3px;
    color: var(--white);
  }
  &__text {
    color: var(--white);
  }
  &__btn {
    text-transform: none;
    span {
      font-weight: normal;
    }
  }
}