//--p-120: calc(3 * var(--x)); //120
//--p-60: calc(2 * var(--x)); //60
//--p-30: calc(var(--x));     //30
//--p-15: calc(var(--x) / 2); //15
//--p-6: calc(var(--x) / 5); //6
//--m-120: calc(3 * var(--x)); //120
//--m-60: calc(2 * var(--x)); //60
//--m-30: calc(var(--x));     //30
//--m-15: calc(var(--x) / 2); //15
//--m-6: calc(var(--x) / 5); //6
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-6 {
  margin-top: var(--m-6);
}
.mb-6 {
  margin-bottom: var(--m-6);
}
.mr-6 {
  margin-right: var(--m-6);
}
.mr-9 {
  margin-right: calc(var(--m-6) * 1.5);
}
.ml-6 {
  margin-left: var(--m-6);
}
.mt-15 {
  margin-top: var(--m-15) !important;
}
.mr-15 {
  margin-right: var(--m-15);
}
.mb-15 {
  margin-bottom: var(--m-15) !important;
}
.ml-15 {
  margin-left: var(--m-15);
}
.ml-30 {
  margin-left: var(--m-30);
}
.ml-60 {
  margin-left: var(--m-60);
}
.mt-30 {
  margin-top: var(--m-30) !important;
}
.mr-30 {
  margin-right: var(--m-30);
}
.mb-30 {
  margin-bottom: var(--m-30) !important;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mt-60 {
  margin-top: var(--m-60);
}
.mb-60 {
  margin-bottom: var(--m-60);
}
.mr-60 {
  margin-right: var(--m-60);
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.hidden {
  display: none !important;
}
.d-block {
  display: block;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.text-uppercase {
  text-transform: uppercase;
}