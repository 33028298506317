.nav-scroll {
  &__inner {
    margin: 0 auto;
    max-width: 1260px;
    &_fixed {
      position: fixed;
      top: 96px;
      left: 50%;
      width: 100%;
      max-width: 1440px;
      transform: translateX(-50%);
      border-radius: var(--border-radius);
      box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
      background-color: var(--white);
      z-index: 50;
    }
  }
}