.switch {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 56px;
  min-height: 26px;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 0;
    width: 56px;
    height: 24px;
    transform: translateY(-50%);
    background-color: var(--pale-grey);
    transition: .4s;
    border-radius: 15px;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      left: 4px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      transition: .3s;
      border-radius: 50%;
      box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
      background-color: var(--white);
      color: var(--white);
      font-size: 10px;
    }
  }

  &__text {
    margin-left: var(--m-15);
    @include text-body-l;
    color: var(--light-blue-grey);
    user-select: none;
    transition: .3s;
  }

  input:checked + .switch__slider {
    background-color: var(--pale);
  }

  input:checked + .switch__slider + .switch__text {
    color: var(--dark);
  }

  input:checked + .switch__slider:before {
    content: '\E80B';
    line-height: 19px;
    transform: translateX(28px) translateY(-50%);
    box-shadow: 0 4px 6px 0 rgba(255, 197, 68, 0.3);
    background-color: var(--butterscotch);
  }
}

