.pagy-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--m-60);
  color: var(--dark);
  .page {
    &:not(:nth-last-child(2)) {
      margin-right: var(--m-15);
    }
  }
  .page.active,
  .page a:active {
    color: var(--soft-green);
  }
  .page.prev,
  .page.next {
    a {
      position: relative;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: solid 2px var(--dark);
      font-size: 0;
      cursor: pointer;
      display: block;
      &::before {
        content: '\e805';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        font-size: 16px;
      }
    }

    &.disabled {
      cursor: default;
      border: solid 2px var(--pale-grey);
      color: var(--pale-grey);
      font-size: 0;
      position: relative;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 0;
      display: block;
      &::before {
        content: '\e805';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        font-size: 16px;
      }
    }
  }
  .page.prev {
    margin-right: var(--m-30);
    a::before {
      transform: translate(-50%, -50%) rotate(-180deg);
    }

    &.disabled {
      &::before {
        transform: translate(-50%, -50%)  rotate(-180deg);
      }
    }
  }
  .page.next {
    margin-left: var(--m-30);
    &.disabled {
      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }
}
