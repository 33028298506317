.mark {
  @include text-body-s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  padding: 2px var(--p-15);
  min-height: 30px;
  height: 20px;
  border-radius: 3px;
  box-sizing: border-box;
  border: none;
  color: var(--white);
  text-transform: uppercase;
  font-weight: bold;
  &_dark {
    background-color: var(--dark);
  }
}