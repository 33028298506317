@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?45686355');
  src: url('../font/fontello.eot?45686355#iefix') format('embedded-opentype'),
  url('../font/fontello.woff2?45686355') format('woff2'),
  url('../font/fontello.woff?45686355') format('woff'),
  url('../font/fontello.ttf?45686355') format('truetype'),
  url('../font/fontello.svg?45686355#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

[class^="icon-"]:before, [class*=" icon-"]:before,
.link-route::before,
.with-lines::before,
.offer-profile::before,
.toast::before,
.offers::before,
.checkbox + label,
.page.prev a::before,
.page.next a::before,
.page.disabled::before,
.switch__slider::before,
.multiselect__tag-icon:before,
.gallery_photo_no-load:before,
.mfp-close:before,
.icon-alert:before,
.marker {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon {
  color: inherit;
  line-height: 1;
  &_150 {
    font-size: 150px;
  }
  &_90 {
    font-size: 90px;
  }
  &_huge {
    font-size: 45px;
  }
  &_bigger {
    font-size: 30px;
  }
  &_big {
    font-size: 24px;
  }
  &_norm {
    font-size: 20px;
  }
  &_small {
    font-size: 16px;
  }
  &_tiny {
    font-size: 12px;
  }
  &_nano {
    font-size: 9px;
  }
  &_sup {
    position: relative;
    margin-left: var(--m-15);
    &:before {
      position: absolute;
      top: -.5em;
    }
  }
}

.icon-add:before { content: '\e800'; } /* '' */
.icon-alert-bad:before { content: '\e801'; } /* '' */
.icon-alert-good:before { content: '\e802'; } /* '' */
.icon-alert-off:before { content: '\e803'; } /* '' */
.icon-alert-on:before { content: '\e804'; } /* '' */
.icon-arrow-back:before { content: '\e805'; } /* '' */
.icon-arrow-down:before { content: '\e806'; } /* '' */
.icon-arrow-up:before { content: '\e807'; } /* '' */
.icon-attach:before { content: '\e808'; } /* '' */
.icon-attention-1:before { content: '\e809'; } /* '' */
.icon-attention:before { content: '\e80a'; } /* '' */
.icon-calendar:before { content: '\e80b'; } /* '' */
.icon-camera:before { content: '\e80c'; } /* '' */
.icon-chat:before { content: '\e80d'; } /* '' */
.icon-chatfull:before { content: '\e80e'; } /* '' */
.icon-check-double:before { content: '\e80f'; } /* '' */
.icon-check-off:before { content: '\e810'; } /* '' */
.icon-check-on:before { content: '\e811'; } /* '' */
.icon-check-one:before { content: '\e812'; } /* '' */
.icon-check:before { content: '\e813'; } /* '' */
.icon-clew:before { content: '\e814'; } /* '' */
.icon-close:before { content: '\e815'; } /* '' */
.icon-compare:before { content: '\e816'; } /* '' */
.icon-done:before { content: '\e817'; } /* '' */
.icon-edit-1:before { content: '\e818'; } /* '' */
.icon-edit:before { content: '\e819'; } /* '' */
.icon-eye-off:before { content: '\e81a'; } /* '' */
.icon-eye-on-1:before { content: '\e81b'; } /* '' */
.icon-eye-on:before { content: '\e81c'; } /* '' */
.icon-favourite:before { content: '\e81d'; } /* '' */
.icon-help:before { content: '\e81e'; } /* '' */
.icon-icons-16-help:before { content: '\e81f'; } /* '' */
.icon-like:before { content: '\e820'; } /* '' */
.icon-lines:before { content: '\e821'; } /* '' */
.icon-minus:before { content: '\e822'; } /* '' */
.icon-more:before { content: '\e823'; } /* '' */
.icon-nophoto:before { content: '\e824'; } /* '' */
.icon-pin-1:before { content: '\e825'; } /* '' */
.icon-pin:before { content: '\e826'; } /* '' */
.icon-remove:before { content: '\e827'; } /* '' */
.icon-sample:before { content: '\e828'; } /* '' */
.icon-search:before { content: '\e829'; } /* '' */
.icon-send:before { content: '\e82a'; } /* '' */
.icon-sewing:before { content: '\e82b'; } /* '' */
.icon-size-full:before { content: '\e82c'; } /* '' */
.icon-stop:before { content: '\e82d'; } /* '' */
.icon-thumb-down:before { content: '\e82e'; } /* '' */
.icon-thumb-up:before { content: '\e82f'; } /* '' */
.icon-thumb:before { content: '\e830'; } /* '' */
.icon-toast-attention:before { content: '\e831'; } /* '' */
.icon-trash:before { content: '\e832'; } /* '' */
.icon-upload:before { content: '\e833'; } /* '' */
.icon-vote:before { content: '\e834'; } /* '' */
.icon-waiting:before { content: '\e835'; } /* '' */
.icon-icons-20-sewing:before { content: '\e836'; } /* '' */
.icon-icons-20-time:before { content: '\e837'; } /* '' */
.icon-icons-20-check:before { content: '\e838'; } /* '' */
.icon-icons-20-stop:before { content: '\e839'; } /* '' */
.icon-icons-16-create:before { content: '\e83a'; } /* '' */
.icon-icons-20-dropdown-close:before { content: '\e83b'; } /* '' */
.icon-icons-20-dropdown-open:before { content: '\e83c'; } /* '' */

/* '' */

.icon-google {
  margin-right: var(--p-30);
  width: 24px;
  height: 24px;
  background: url("../images/icons/google.svg") no-repeat center;
}

.btn_disabled,
.btn:hover {
  .icon-google {
    background-image: url("../images/icons/google-disabled.svg");
  }
}

.icon-favourites {
  margin-right: var(--p-30);
  width: 20px;
  height: 20px;
  background: url("../images/icons/favourite.svg") no-repeat center;
  background-size: contain;
  &_active {
    background-image: url("../images/icons/favourite-active.svg");
  }
}