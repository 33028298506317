.rate-icons {
  display: flex;
  font-size: 20px;
  .icon-clew {
    color: var(--light-blue-grey);
    &:not(:last-of-type) {
      margin-right: var(--m-6);
    }
  }
  &_good {
    .icon-clew {
      color: red;
      //color: var(--butterscotch);
    }
  }
  &_average {
    .icon-clew {
      color: var(--soft-green);
    }
  }

  &__icon_disabled {
    opacity: 0.1;
  }
}