.offer-form {
  padding: var(--p-30) 90px var(--p-60) 90px;
  &__toggle {
    margin-bottom: var(--m-30);
    margin-top: calc(var(--m-15) * -1);
    padding: var(--p-30);
    border-radius: 3px;
    background-color: #f7fcfc;
  }
  &__title-drag {
    margin-top: 48px;
    margin-bottom: 21px;
    color: #b5b9cc;
  }
  &__drop-logo {
    width: 240px;
  }
  &__logo-wrap {
    display: flex;
    align-items: center;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--m-30);
    width: 100px;
    height: 90px;
    img {
      max-height: 90px;
    }
  }
  &__remove-logo {
    cursor: pointer;
    color: var(--light-blue-grey);
    .icon {
      margin-right: 15px;
    }
  }
  &__hints-wrap {
    margin-top: 5px;
    padding: 0 var(--p-30);
    a {
      position: relative;
      display: inline-block;
      @include text-caption-m;
      color: var(--light-blue-grey);
      cursor: pointer;
      user-select: none;
      &:not(:last-of-type) {
        margin-right: var(--m-15);
      }
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, var(--light-blue-grey) 58%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 8px 1px;
        background-repeat: repeat-x;
      }
    }
  }
}