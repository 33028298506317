.message {
  display: flex;

  &__icon {
    margin-right: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background-color: var(--pale-grey);
    border-radius: 50%;
    background-size: contain;
  }

  &__text-wrap {
    position: relative;
    flex-grow: 1;
    padding: 17px var(--p-30) 21px var(--p-30);
    border-radius: var(--border-radius);
    background-color: var(--pale-grey);
    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: -10px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid var(--pale-grey);
      border-bottom: 10px solid transparent;
    }
    &_pale {
      background-color: var(--pale);
      &::before {
        border-right: 10px solid var(--pale);
      }
    }
  }

  &__text-small {
    color: var(--light-blue-grey);
    line-height: 1;
  }
}