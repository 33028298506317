.popup {
  position: relative;
  padding: 48px var(--p-60) var(--p-60) var(--p-60);
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
  background-color: var(--white);
  &_p-30 {
    padding: var(--p-30);
  }
}
.popup-success {
  padding: var(--p-60) 69px var(--p-60) var(--p-60);
}
.popup-success__text {
  line-height: 2;
}