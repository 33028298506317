.offers {
  position: relative;
  padding: var(--p-15) 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &::before {
    content: '\e826';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 120px;
    color: #fff9ed;
  }

  &__btn-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: var(--m-15);

    .btn-small {
      margin-bottom: var(--m-6);
      &:not(:first-child) {
        margin-left: var(--m-30);
      }
    }
  }
}