.layer {
  border-radius: var(--border-radius);
  background-color: var(--white);
  &_black {
    background-color: var(--dark);
  }
  &_black {
    background-color: var(--dark);
  }
  &_black {
    background-color: var(--dark);
  }
  &_grey {
    background-color: var(--pale-grey-two);
  }
  &_padding-30 {
    padding: var(--p-30);
  }
  &_padding-15 {
    padding: var(--p-15);
  }
  &_with_padding {
     padding: 25px var(--p-60) var(--p-60) var(--p-60);
   }
  &_with_padding2 {
    padding: 45px var(--p-60) var(--p-30) var(--p-60);
  }
  &_with_padding_big {
    padding: 25px var(--p-90) var(--p-90) var(--p-90);
  }
}

.layer-small {
  padding: var(--p-15) var(--p-30) var(--p-30) var(--p-30);
  border-radius: 3px;
  background-color: #f2faf9;
  &_padding30 {
    padding: var(--p-30);
  }
}

.layer-agreement {
  padding: var(--p-15) var(--p-30);
  background-color: #fff9ed;
}
.layer-chat {
  padding: var(--p-15) var(--p-60) var(--p-30) var(--p-60);
}