.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  background: var(--pale-grey);
  .btn-small {
    align-self: center;
  }
}