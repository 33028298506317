.anonymous {
  display: flex;
  align-items: center;
  padding: var(--p-15) var(--p-30);
  border-radius: var(--border-radius);
  background-color: #f7fcfc;
  .checkbox-wrap {
    margin-bottom: 0;
    flex-grow: 1;
  }
}