.tooltip {
  left: -147px !important;
  width: 330px;
  height: 102px;
  padding: 9px 15px;
  border-radius: 12px;
  box-shadow: 0 10px 10px 0 rgba(207, 222, 219, 0.2);
  background-color: var(--ice);
  font-family: var(--font-family-sans-serif);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--dark);
}
.tooltip__btn {
  padding: 5px;
  border: none;
  background: none;
  outline: none;
  &:hover {
    .icon {
      color: var(--soft-blue);
    }
  }
}
.bs-tooltip-top {
  top: -5px !important;
}
.bs-tooltip-bottom {
  bottom: 5px !important;
}