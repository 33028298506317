*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html {
  font-family: var(--font-family-sans-serif);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--ice);
}

body {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding: var(--p-15);
}
img {
  max-width: 100%;
  height: auto;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  & > * {
    flex-grow: 1;
  }
}
.container-small {
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
}

.container-570 {
  margin: 0 auto;
  max-width: 570px;
  width: 100%;
}

.form-actions {
  text-align: right;
}

.container-flex {
  display: flex;
  &__left,
  &__right-big {
    flex-grow: 1;
  }
  &__right,
  &__left-small {
    display: flex;
    flex-direction: column;
    width: 349px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: var(--m-15);
  }
  &__left-small {
    margin-left: 0;
    margin-right: var(--m-15);
    max-height: calc(100vh - 130px);
    min-height: 400px;
    overflow: auto;
    &#chats_menu .mb-15:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-end {
  align-items: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}