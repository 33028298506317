.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px var(--p-15);
  min-height: 71px;
  border-radius: 3px;
  border: none;
  &_ok {
    border: none;
    border-radius: 3px;
    background-color: #f2faf9;
    .notification__text {
      color: var(--soft-green);
    }
    .notification__icon {
      color: var(--soft-green);
    }
  }
  &_error {
    border-radius: 3px;
    background-color: #fff4f3;
    .notification__text {
      color: var(--coral);
    }
    .notification__icon {
      color: var(--coral);
    }
    .notification__link {
      color: var(--coral);
    }
  }
  &_waiting {
    justify-content: flex-start;
    border: none;
    border-radius: 3px;
    background-color: #fff6e6;
    .notification__text {
      color: var(--dark);
    }
    .notification__icon {
      color: var(--dark);
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    color: var(--white);
  }
  &__text {
    position: relative;
    padding-left: 40px;
  }
}
.notification__link {
  text-decoration: underline;
}
.notification__btn {
  flex-shrink: 0;
  align-self: center;
}