.avatar-edit {
  display: flex;
  align-items: center;
  margin-bottom: var(--m-60);
  &__avatar-wrap {
    flex-shrink: 0;
    position: relative;
    margin-right: var(--m-60);
  }
  &__avatar {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--pale-grey);
    cursor: pointer;
    * {
      pointer-events: none;
    }
  }
  &__avatar-overflow {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: dashed 1px var(--soft-blue);
    background-color: rgba(81, 142, 248, 0.07);
    &.active {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__icon {
    color: var(--soft-blue);
  }
  &__btn-wrap {

  }
}