.filter-deals {
  .filters__inner {
    max-width: none;
  }
  .filters__wrap {
    padding: 20px var(--p-15);
  }
  &__wrap {
    padding-top: var(--p-15);
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1290px;
  }
  &__search {
    width: 100%;
  }
}
.filter-deals__row {
  margin-left: -7px;
  margin-right: -7px;
  width: 100%;
}
.filter-deals__col {
  padding-left: 7px;
  padding-right: 7px;
}