form {
  position: relative;
}
.input,
.form-inputs {
  position: relative;
  margin-bottom: var(--p-30);

  .label {
    display: inline-block;
    @include text-body-l;
    margin-bottom: 5px;
  }
  input:not(.multiselect__input),
  textarea {
    @include text-body-m;
    padding: 0 var(--p-30);
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    color: var(--dark);

    &::placeholder {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.4px;
      color: #b5b9cc;
    }

    &:focus {
      color: var(--dark);
      border-color: var(--pale-grey);
      box-shadow: none;
      outline-style: none;
      &::placeholder {
        font-size: 0;
      }
    }
  }
  textarea {
    padding-top: 14px;
    padding-bottom: 10px;
    height: auto;
    resize: none;
  }
}

.input.error {
  input {
    border: solid 1px var(--coral);
  }
}
.input.completed input {
  border: solid 1px var(--soft-green);
}

.input_with-btn {
  position: relative;
  input {
    padding-right: 160px;
  }
}
.btn-submit-in-input {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  &:disabled {
    box-shadow: 0 10px 10px 0 rgba(255, 197, 68, 0.3);
    background-color: var(--butterscotch);
  }
}

.marker {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  color: var(--light-blue-grey);
  background-size: 100%;
  cursor: pointer;
}

.markerCross {
  top: 30px;
  right: var(--p-30);

  &::before {
    font-size: 16px;
    content: '\E827';
    color: var(--light-blue-grey);
  }

  &_left-position {
    right: 80px;
  }
}

.marker-show-password {
  top: 30px;
  right: var(--p-30);

  &::before {
    font-size: 16px;
    content: '\E81A';
    color: var(--light-blue-grey);
  }

  &_active {
    &::before {
      content: '\E81B';
    }
  }
}
.error-message {
  @include text-caption-m;
  position: absolute;
  top: calc(100% + 0px);
  left: var(--p-30);
  color: var(--coral);
}
.error-message-top {
  @include text-caption-m;
  position: absolute;
  top: -40px;
  left: 0;
  color: var(--coral);
}

.input-placeholder {
  @include text-caption-m;
  position: absolute;
  top: 0;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 var(--p-15);
  background: linear-gradient(180deg, transparent 0%, transparent 50%,var(--white) 50%, var(--white) 100%);
  color: var(--light-blue-grey);
  &_green {
    color: var(--soft-green);
  }
}
.input-reset {
  margin-right: var(--m-6);
  flex-grow: 1;
  input:not(.multiselect__input) {
    padding: 2px var(--p-6);
    //width: 240px;
    height: 30px;
    border-radius: 3px;
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
  }
  .input-placeholder,
  .marker {
    display: none;
  }
}
.error-message-right {
  position: absolute;
  top: 0;
  left: calc(100% + var(--p-30));
  width: 200px;
  @include text-caption-m;
  &__title {
    color: var(--coral);
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--dark);
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  min-height: 24px;

  &:not(:last-of-type) {
    margin-bottom: var(--p-30);
  }

  .checkbox {
    display: none;
  }

  .checkbox + label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 39px;
    user-select: none;
    &:focus {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -6px;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        border-radius: 3px;
        border: dashed 1px var(--soft-green);
        background-color: var(--ice);
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      line-height: 1;
      width: 24px;
      font-size: 24px;
      content: '\e810';
      color: var(--dark);
    }
  }
}

.checkbox:checked + label {
  &::after {
    content: '\e811';
    color: var(--soft-green);
  }
  &::before {
    content: none !important;
  }
}

.checkbox_black:checked + label,
.checkbox_light-blue-grey:checked + label {
  &::after {
    color: var(--dark) !important;
  }
}

.checkbox_light-blue-grey + label {
  &::after {
    color: var(--light-blue-grey) !important;
  }
}

.radio-btn {
  display: flex;
  label {
    &:not(:last-of-type) {
      margin-right: var(--m-15);
    }
  }
  span {
    @include text-body-m;
    display: flex;
    align-items: center;
    padding: 0 var(--p-30);
    min-height: 45px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 30px;
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    color: #b5b9cc;
    cursor: pointer;
    user-select: none;
  }
  input:checked + span   {
    background-color: var(--pale);
    border-color: var(--pale);
    color: var(--dark);
  }
}

.textarea {
  @include text-body-m;
  padding: 14px var(--p-30);
  width: 100%;
  box-sizing: border-box;
  resize: none;
  border-radius: var(--border-radius);
  border: solid 1px var(--pale-grey);
  background-color: var(--white);
  &::placeholder {
    color: #b5b9cc;
  }
}
.input-simple {
  position: relative;
  margin-bottom: 0;
  &__remove {
    position: absolute;
    top: 50%;
    right: var(--m-30);
    transform: translateY(-50%);
    padding: var(--p-6);
    cursor: pointer;
    color: var(--light-blue-grey);
    &_hide {
      display: none;
    }
  }
}