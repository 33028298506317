.list-work {
  padding: 49px var(--p-30) var(--p-60) var(--p-30);
  border-radius: var(--border-radius);
  border: solid 2px var(--dark);
  text-align: center;
  &_edit {
    padding: 19px var(--p-30) 32px var(--p-30);

  }
  &__list {
    li {
      @include text-body-m;
      display: flex;
      justify-content: space-between;
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
      span {
        text-align: right;
        &:first-of-type {
          flex-shrink: 0;
          margin-right: var(--m-15);
          width: calc(40% - #{var(--m-15)});
          text-align: left;
        }
      }
      .icon {
        color: var(--light-blue-grey);
        cursor: pointer;
      }
    }
  }
  &__btn {
    margin-top: 21px;
  }
}