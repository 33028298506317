.rate-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--p-30);
  max-width: 507px;
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  border: solid 1px var(--pale-grey);
  background-color: var(--white);
  &_active {
    border-radius: var(--border-radius);
    background-color: var(--pale);
  }
  .rate-icons {
    cursor: pointer;
  }
}