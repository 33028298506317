.popup-offer {
  &__top {
    &_last-message {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .popup-offer__top__btn {
        display: block;
      }
      .popup-offer__top__title {
        margin-bottom: 8px;
      }
      .popup-offer__top__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  &__top__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  &__top__title {
    margin-bottom: 0;
    line-height: 1.3;
  }
  &__top__btn {
    display: none;
  }
  &__title-small {
    margin-top: 9px;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: var(--light-blue-grey);
  }

}