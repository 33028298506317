.gallery {
  position: relative;
  display: grid;
  grid-template-columns: minmax(200px, 417px) repeat(3, minmax(50px, 135px));
  grid-auto-rows: 135px;
  grid-column-gap: var(--p-6);
  grid-row-gap: var(--p-6);
  @include breakpoint-md {
    grid-template-columns: minmax(200px, 417px) repeat(1, minmax(50px, 135px));
    grid-auto-rows: 65px;
    &_photo_middle {
      display: none;
    }
  }
  &__logo {
    position: absolute;
    top: var(--p-15);
    left: var(--p-15);
    max-width: 100px;
    max-height: 90px;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  &__icon-remove {
    position: absolute;
    top: var(--p-6);
    right: var(--p-6);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: rgba(var(--dark_rgb), .5);
    cursor: pointer;
    .icon {
      font-size: 20px;
      color: var(--white);
    }
  }
  &_photo {
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: #d8d8d8 no-repeat center;
    background-size: cover;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &_no-load {
      position: relative;
      background-color: #f7fcfc;
      &::before {
        content: '\E824';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 45px;
        width: 45px;
        height: 45px;
        color: var(--light-blue-grey);
      }
    }
    &_big {
      grid-row-start: 1;
      grid-row-end: 4;
    }
    &_middle {
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    &_dark {
      position: relative;
      span {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(var(--dark_rgb), .5);
        color: var(--white);
      }
    }
  }
}

.gallery-drop {
  .gallery_photo {
    cursor: grab;
  }
}