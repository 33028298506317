.offer-profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 42px var(--p-30) var(--p-30) 0;

  &::before {
    content: '\e822';
    position: absolute;
    top: 0;
    left: calc(var(--p-30) * -1);
    width: 224px;
    height: 120px;
    font-size: 120px;
  }
  &_green {
    &::before {
      color: #f2fdf4;
    }
    .with-lines__polygon {
      background-color: var(--soft-green);
    }
  }
  &__avatar {
    position: relative;
    margin-right: var(--m-30);
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: no-repeat center;
    background-size: contain;
  }
  .rate-small2 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__title {
    margin-bottom: 21px;
  }
  &__text-wrap {
    position: relative;
    margin-right: var(--m-30);
  }
}