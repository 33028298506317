.dotted-line {
  position: relative;
  color: #b5b9cc;
  text-align: center;
  height: 1px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, var(--light-blue-grey) 10%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 7px 1px;
    background-repeat: repeat-x;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    padding: 0 var(--p-6);
    background-color: var(--white);
  }
}

.dotted-line-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-image: linear-gradient(180deg, #aaaebf, #aaaebf 20%, transparent 20%, transparent 100%);
  background-size: 1px 5px;
  border: none;
}

.rectangle {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  flex-shrink: 0;
  &_coral {
    border-radius: 12px;
    background-color: var(--coral);
  }
  &_dark {
    background-color: var(--dark);
  }
  &_soft-green {
    background-color: var(--soft-green);
  }

  &_light-blue-grey {
    background-color: var(--light-blue-grey);
  }
  &_pale-grey {
    background-color: var(--pale-grey);
  }
  &_small {
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }

  &_45 {
    width: 45px;
    height: 45px;
    border-radius: 6px;
  }

  &_norm {
    width: 90px;
    height: 90px;
    border-radius: 15px;
  }

  &_big {
    width: 120px;
    height: 120px;
    border-radius: 12px;
    flex-shrink: 0;
  }
  &_large {
    width: 240px;
    height: 240px;
  }
}

.responded {
  position: absolute;
  bottom: 50%;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-15);
  height: 30px;
  border-radius: 3px;
  background-color: var(--pale);
  color: var(--butterscotch);
  white-space: nowrap;
}

.completed {
  position: absolute;
  bottom: 50%;
  left: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-15);
  height: 30px;
  border-radius: 3px;
  background-color: var(--soft-green);
  color: var(--white);
  white-space: nowrap;
}

.text-dashed {
  border-bottom: 1px dashed var(--dark);
}

.icon-delete {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 0 6px 0 6px;
  background-color: var(--dark);
  color: var(--white);
  cursor: pointer;
  z-index: 6;
}
