.deals-item-customer {
  padding: 9px 14px var(--p-15) var(--p-15);
  border-radius: 3px;
  background-color: var(--pale-grey-two);
  &_ok {
    background-color: var(--white);
    .deals-item-customer__icon {
      background-color: var(--soft-green);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-customer__text {
      color: var(--soft-green);
    }
  }
  &_waiting {
    background-color: var(--white);
    .deals-item-customer__icon {
      background-color: var(--butterscotch);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-customer__text {
      color: var(--butterscotch);
    }
  }
  &_error {
    background-color: var(--white);
    .deals-item-customer__icon {
      background-color: var(--coral);
      .icon {
        color: var(--white);
      }
    }
    .deals-item-customer__text {
      color: var(--coral);
    }
  }
  &_3 {
    background-color: var(--white);
  }
  &_4 {
    background-color: var(--white);
  }

}
.deals-item-customer__icon {
  text-align: center;
  width: 30px;
  height: 30px;
  margin: 0 9px 0 0;
  border-radius: 50%;
  flex-shrink: 0;
  .icon {
    line-height: 30px;
  }
}
.deals-item-customer__logo-wrap {
  position: relative;
  width: 15px;
  height: 30px;
  flex-shrink: 0;
}
.deals-item-customer__logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: var(--pale-grey) no-repeat center;
  border-radius: 50%;
  background-size: cover;
}