.popup.popup-remove-factory {
  padding: var(--p-60);
  max-width: 600px;
}
.remove-factory {
  &__rectangle {
    margin-bottom: 45px;
    height: 240px;
    border-radius: 6px;
    background-color: var(--dark);
  }
  &__text {
    margin-bottom: 50px;
  }
  .btn-wrap {
    margin-top: 0;
  }
}