.light-blue-grey {
  color: var(--light-blue-grey);
}
.color-soft-green {
  color: var(--soft-green);
}
.color-light-grey-blue {
  color: var(--light-grey-blue);
}
.color-coral {
  color: var(--coral);
}