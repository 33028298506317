.design-files {
  display: flex;
  align-items: center;
  img {
    flex-shrink: 0;
  }
  &__extension {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7daba3;
    width: 45px;
    height: 45px;
    border-radius: 6px;
    text-transform: capitalize;
    padding: 4px;
    span {
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__desc {
    margin-left: var(--m-30);
  }
}