.sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__left {

  }
  &__right {
    display: flex;
    p {
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: var(--m-60);
      }
    }
    .icon {
      margin-left: var(--m-15);
    }
  }
  &__count {
    .icon {
      margin-left: var(--m-6);
    }
  }
}