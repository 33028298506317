.comment-sample {
  display: flex;
  align-items: flex-end;
  &_center {
    align-items: center;
  }

  &__icon {
    margin-right: var(--m-30);
    flex-grow: 0;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background-color: var(--pale-grey);
    border-radius: 50%;
    background-size: contain;
    &_small {
      margin-right: var(--m-15);
      width: 30px;
      height: 30px;
    }
  }

  &__text-wrap {
    position: relative;
    padding: var(--p-15) var(--p-30);
    flex-grow: 1;
    background-color: var(--pale-grey);
    border-radius: 20px 20px 20px 0;
    &_pale {
      background-color: var(--pale);
      &::before {
        border-right: 10px solid var(--pale);
      }
    }
  }
  &__text-wrap-error {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px var(--p-15);
    border-radius: 20px 20px 20px 0;
    background-color: var(--coral);
    min-height: 45px;
    &::before {
      border-right: 10px solid var(--coral);
    }
    & > div {
      color: var(--white);
    }
  }

  &__text-small {
    color: var(--light-blue-grey);
    line-height: 1;
  }
  &__comment {
    border-bottom: 1px dashed  var(--dark);
    cursor: pointer;
  }
}