@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: .7;
  }
}

.loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  opacity: 0;
  background-color: gray;
  z-index: 9999;
  text-align: center;
  &_active {
    display: block;
    animation: fadeInFromNone 0.7s;
    animation-fill-mode: forwards;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 30px;
    background: url("../images/loader/loader.svg") no-repeat center;
  }
}