.contacts {
  padding-top: var(--p-15);
  &__title {
    margin-bottom: 20px;
  }
  &__wrap {
    padding: 0 var(--p-30);
    border-radius: 3px;
    .contacts__item:not(:last-of-type) {
      margin-bottom: 0;
    }
    &_switch-on {
      background-color: #fff9ed !important;
    }
    &_switch-off {
      background-color: #f7fcfc !important;
    }
    &_edit {
      padding-bottom: var(--p-30);
      .contacts__text-wrap {
        align-self: auto;
      }
    }
    .btn-wrap {
      margin-top: 0;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    min-height: 75px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: var(--m-30);
    }
  }
  &__img {
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background: var(--pale-grey) no-repeat center;
    border-radius: 50%;
    background-size: cover;
  }
  &__text-wrap {
    padding: 0 var(--p-30);
    margin-right: auto;
    align-self: flex-start;
  }
  &__btn {
    align-self: center;
    .icon {
      margin-right: 0;
    }
  }
  &__btn-edit {
    padding: 10px;
    margin-right: var(--m-30);
    color: var(--light-blue-grey);
    cursor: pointer;
  }
  &__input-wrap {
    display: flex;
    align-items: center;
    margin-bottom: var(--m-15);
  }
}