.status {
  &__wrap {
    padding-top: var(--p-60);
    .status:not(:last-of-type) {
      margin-bottom: var(--m-15);
    }
    .status:last-of-type:after {
      content: none;
    }
  }
  position: relative;
  padding-left: 45px;
  &_with-arrow {
    padding-bottom: 10px;
    &:after {
      content: '';
      position: absolute;
      top: 45px;
      left: 15px;
      width: 1px;
      height: 15px;
      background-color: var(--pale-grey);
    }
  }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    color: var(--white);
    &_1 {
      background-color: var(--soft-green);
    }
    &_2,
    &_3 {
      background-color: var(--butterscotch);
    }
    &_4 {
      background-color: var(--coral);
    }
    &_5 {
      background-color: var(--dark);
    }
    &_6 {
      background-color: var(--coral);
    }
    &_7 {
      background-color: var(--dark);
    }
  }
  &__text {
    &_1 {
      color: var(--soft-green);
    }
    &_2 {
      color: #aaaebf;
    }
    &_3 {
      color: var(--butterscotch);
    }
    &_4 {
      color: var(--coral);
    }
    &_5 {
      color: var(--dark);
    }
    &_6 {
      color: var(--coral);
    }
    &_7 {
      color: #aaaebf;
    }
  }
}