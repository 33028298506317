.input-verified {
  position: relative;
  display: inline-block;
  height: 45px;
  border-radius: 6px;
  border: solid 1px var(--pale-grey);
  background-color: var(--white);
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    background: url('../images/icons/verified-grey.svg') no-repeat center;
    background-size: cover;
  }
  &_checked {
    &::before {
      background-image: url('../images/icons/verified.svg');
    }

  }
}