@mixin title {
  font-family: 'Prata', serif;
  font-size: 48px;
  @include responsive-font-size(48);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 3px;
  color: var(--dark);
}

@mixin title-small {
  font-family: var(--font-family-prata);
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1.8px;
  color: var(--black);
}

@mixin text-body-s {
  font-family: var(--font-family-sans-serif);
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--dark);
}

@mixin text-body-m {
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 1.4px;
  color: var(--dark);
}

@mixin text-body-l {
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 1.4px;
  color: var(--dark);
}

@mixin text-caption-m {
  font-family: var(--font-family-source-sans-pro);
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--dark);
}

@mixin  text-caption-l {
  font-family: var(--font-family-source-sans-pro);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--dark);
}

@mixin breakpoint-sm {
  @media only screen and (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin breakpoint-md {
  @media only screen and (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin link {
  font-family:  var(--font-family-source-sans-pro);
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  color: var(--dark);
  text-decoration: underline;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  &:hover {
    text-decoration: none;
  }
  &:active {
    color: var(--light-blue-grey);
  }
}