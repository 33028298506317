.log-in {
  &__left {
    min-height: 467px;
  }
  &__right {
    align-self: center;
  }
  &__rules {
    margin-bottom: var(--p-30);
    padding: 10px var(--p-15);
    border-radius: var(--border-radius);
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    overflow-y: scroll;
  }
  &__rules__inner {
    height: 180px;
  }
  &__btn-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__form-wrap {
    position: relative;
  }
  &__link-reset {
    position: absolute;
    top: calc(100% - 64px / 2);
    left: 0;
    user-select: none;
    &_active {
      color: var(--light-blue-grey);
      text-decoration: none;
    }
  }
  &__sing-in-google {
    height: 60px;
    border-radius: 30px;
    box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
    background-color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
  }
}