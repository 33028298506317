.notification-item {
  display: flex;
  padding: var(--p-6) var(--p-15);
  margin-bottom: 3px;
  min-height: 57px;
  background-color: #fff9ed;
  &.read {
    background-color: var(--white);
  }
  &:hover {
    background-color: var(--pale);
  }
  &_pined {
    background-color: var(--pale-grey-two);
  }
  &__left {
    margin-right: 15px;
    width: 45px;
    height: 45px;
    flex-shrink: 0;
  }
  &__avatar {
    position: relative;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background: var(--pale-grey) no-repeat center;
    border-radius: 50%;
    background-size: cover;
  }
  &__avatar-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color: var(--coral);
    border-radius: 50%;
  }
  &__right {

  }
}
.notification-item__tooltip-wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.notification-item__icon-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.notification-item__icon-small {
  width: 22px;
  height: 22px;
  background: no-repeat center / contain;
}
.notification-item__icon-top-left {
  border-top-left-radius: 20px;
}
.notification-item__icon-top-right {
  border-top-right-radius: 20px;
}
.notification-item__icon-bottom-left {
  border-bottom-left-radius: 20px;
}
.notification-item__icon-bottom-right {
  border-bottom-right-radius: 20px;
}

.notification-item__img-wrap {
  width: 45px;
  height: 45px;
  position: relative;
  flex-shrink: 0;
  &.block {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../images/notification/stop.svg") no-repeat center / cover;
    }
  }
  &.grey {
    background-color: var(--pale-grey);
  }
  &.round {
    border-radius: 50%;
  }
}

.notification-item__img-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--white);
  &.good {
    background-color: var(--soft-green);
  }
  &.bad {
    background-color: var(--coral);
  }
  &.round {
    border-radius: 50%;
  }
  &.gray {
    background-color: #aaaebf;
  }
  &.butterscotch {
    background-color: var(--white);
    .icon {
      color: var(--butterscotch);
    }
  }
  &.purple {
    background-color: #ff7ab6;
  }
  &.edit {
    background-color: var(--white);
    .icon {
      color: #aaaebf;
    }
  }
}

.notification-item__icon {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  .icon {
    line-height: 45px;
    color: var(--white);
  }
  &.green {
    background-color: var(--soft-green);
  }
  &.bad {
    background-color: var(--coral);
  }
  &.grey {
    background-color: #aaaebf;
  }
  &.round {
    border-radius: 50%;
  }
}
.notification-item__inner {
  display: flex;
}
.notification-item__pined-wrap {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  padding: 15px 10px;
  .icon {
    color: #aaaebf;
  }
}