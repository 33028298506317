.save-favourites {
  position: relative;
  width: 240px;
  min-height: 51px;
  &:hover {
    .save-favourites__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
      min-height: 161px;
      background-color: var(--white);
      z-index: 5;
    }
    .save-favourites__hidden {
      display: block !important;
    }
  }
  &__inner {
    padding: var(--p-15);
    border-radius: 4px;
    cursor: pointer;
  }
  &__top {
    display: flex;
  }
  &__hidden {
    display: none;
    margin-top: var(--m-15);
  }
  input {
    @include text-caption-m;
    padding: 2px var(--p-15);
    margin-bottom: var(--m-15);
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    color: var(--dark);
    border-radius: 3px;
    border: solid 1px var(--pale-grey);
    &::placeholder {
      @include text-caption-m;
      color: var(--light-blue-grey);
    }
  }
  ul {
    margin: 0 calc(var(--m-15) * -1);
  }
  li {
    display: flex;
    align-items: center;
    padding: 0 var(--p-30);
    min-height: 40px;
    &:hover {
      background-color: var(--ice);
    }
  }
}