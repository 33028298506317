.icon-girl {
  width: 150px;
  height: 150px;
  background: no-repeat center;
  background-size: contain;
  &_delivery {
  background-image: url('../images/icons/delivery.png')
  }
  &_sewing {
    background-image: url('../images/icons/sewing.png')
  }
}