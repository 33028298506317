.samples {
  &__top {
    padding-bottom: var(--p-45);
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    margin-bottom: 0;
    margin-right: var(--m-15);
  }
  .nav-scroll__block {
    display: none;
    position: fixed;
    top: 80px;
    left: 50%;
    max-width: 1440px;
    transform: translateX(-50%);
    width: 100%;
    height: 20px;
    background-color: var(--white);
    opacity: .95;
    z-index: 10;
  }
  .nav-scroll.active {
    .nav-scroll__block {
      display: block;
    }
  }
  .nav-scroll__inner_fixed {
    padding: var(--p-15) 0;
    .samples__title {
      @include title-small;
    }
    .samples__btn-chat {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: solid 2px #aaaebf;
        i {
          margin-right: 0;
        }
        .text {
          display: none;
        }
      }
    }
  }
}

.big-chat {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: solid 2px #aaaebf;
    i {
      margin-right: 0 !important;
    }
  }
}