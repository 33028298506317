.factory-remove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--p-60);
  margin-bottom: 5px;
  &__text {
    color: var(--light-blue-grey);
  }
  .btn-outline-square {
    &:not(:last-of-type) {
      margin-right: var(--m-30);
    }
  }
}