.dropdown-toggle::after {
  content: none;
}
.dropdown-toggle {
  border: none;
  background-color: transparent;
  padding: 0;
}

// The dropdown wrapper (`<div>`)
.dropdown {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px) !important;
  left: 0;
  z-index: 1000;
  display: none;
  &_fixed {
    transform: none !important;
  }
}

.dropdown-menu.show {
  display: block;
}
.dropdown-menu__link {
  @include text-body-m;
  display: block;
  padding: 0 var(--p-30);
  line-height: 40px;
  height: 40px;
  &:hover {
    background-color: var(--ice);
  }
}