.working-in {
  padding: 47px var(--p-60) var(--p-60) var(--p-60);

  &__title {
    margin-bottom: 20px;
  }

  &__img-wrap {
    position: relative;
    margin-bottom: 17px;
    border-radius: 6px;
    overflow: hidden;
    .rate-small {
      position: absolute;
      bottom: var(--m-6);
      right: var(--m-6);
    }
  }
  &__img-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 231px;
    height: 154px;
    border-radius: 6px;
    background-color: #f7fcfc;
    color: var(--light-blue-grey);
    font-size: 53px;
  }
  &__name {
    margin-bottom: 12px;
  }
}