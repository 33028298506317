.send-message {
  padding: var(--p-15);
  border-radius: 6px;
  background-color: var(--pale);

  &__textarea-wrap {
    position: relative;
    textarea {
      padding-right: 130px;
    }
  }
  &__btn {
    position: absolute !important;
    bottom: var(--m-15);
    right: var(--m-15);
  }
}