.tips {
  padding: 47px var(--p-60) var(--p-60) var(--p-60);
  &__title {
    margin-bottom: 20px;
  }
  &__li {
    display: flex;
    align-items: flex-start;
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
}
.tips__icon {
  margin-top: 3px;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: url('../images/icons/faq.svg') no-repeat center;
  background-size: contain;
}