.filters {
  &__wrap {
    padding-top: var(--p-15);
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 1290px;
  }
  &__search {
    margin-right: var(--m-15);
    margin-bottom: var(--m-15);
    width: 768px;
    &_big {
      margin-right: 0;
      width: 100%;
    }
  }
  &__rating {
    margin-bottom: var(--m-15);
    width: 447px;
    flex-shrink: 0;
    flex-grow: 1;
  }
  &__verified {
    margin-bottom: var(--m-15);
    width: 45px;
    flex-shrink: 0;
    flex-grow: 0;
    @media only screen and (max-width: 1439px) {
      margin-right: var(--m-15);
    }
  }
  &__select {
    margin-bottom: var(--m-15);
    width: 246px;
    flex-shrink: 0;
    flex-grow: 0;
    &:not(:last-of-type) {
      margin-right: var(--m-15);
    }
  }
}