.main-header {
  position: relative;
  height: 90px;
  margin-bottom: var(--m-15);

  &__inner {
    display: flex;
    width: 1410px;
    max-width: 100vw;
    align-items: center;
    padding: 10px var(--p-60);
    min-height: 90px;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    background-color: var(--white);
    &_fixed {
      position: fixed;
      top: 0;
      left: 50%;
      width: 100%;
      max-width: 1440px;
      transform: translateX(-50%);
      box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
      z-index: 51;
    }
  }
  &_show-popup {
    position: relative;
    z-index: 9999;
  }
  .logo {
    margin-right: var(--m-60);
    font-size: 30px;
    color: var(--soft-green);
    text-shadow: 0 10px 10px rgba(95, 202, 114, 0.3);
    flex-shrink: 0;
  }
  &__list {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    li {
      &:not(:last-of-type) {
        margin-right: var(--m-60);
      }
      &.active a {
        position: relative;
        cursor: default;
        pointer-events: none;
        user-select: none;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          height: 3px;
          background-color: var(--soft-green);
        }
      }
    }
  }
  &__icons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--m-60);
    margin-left: var(--m-60);
    & > li {
      flex-shrink: 0;
      &:not(:last-of-type) {
        margin-right: var(--m-60);
      }
      & > a {
        color: var(--light-blue-grey);
      }
    }
  }
  &__profile {
    display: flex;
    align-items: center;
  }
  &__sub {
    position: absolute;
    top: calc(var(--m-15) * -1);
    right: calc(var(--m-30) * -1);
    padding: var(--p-15) 0 21px 0;
    width: calc(100% + var(--p-30) * 2);
    min-width: 237px;
    border-radius: 8px;
    box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
    background-color: var(--white);
    z-index: 15;
    .main-header__profile-inner {
      justify-content: flex-end;
    }
  }
  &__sub__list {
    text-align: right;
  }
  &__profile-menu {
    position: relative;
  }
  &__profile-inner {
    display: flex;
    align-items: center;
    user-select: none;
    border: none;
    background-color: transparent;
    padding: 0;
    &_2 {
      padding: 0 var(--p-30);
    }
  }
  &__link-to-reg {
    margin-right: var(--m-30);
  }
  &__search {
    margin-right: 48px !important;
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      height: 43px;
      border-radius: 50%;

      &:active {
        background-color: var(--soft-green);
        color: var(--white);
      }
    }
    &_active a {
      background-color: var(--soft-blue);
      color: var(--white) !important;
    }
  }
  &__profile__avatar {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 3px;
      width: 30px;
      height: 30px;
      background-color: var(--white);
      border-radius: 50%;
    }
    .main-header__profile__logo {
      position: relative;
    }
  }
  &__profile__logo {
    width: 30px;
    height: 30px;
    background: var(--pale-grey) no-repeat center;
    border-radius: 50%;
    background-size: cover;
  }
  &__profile__text {
    color: #b5b9cc;
    span {
      margin-right: var(--p-15);
    }
  }
}

.main-header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: var(--light-grey-blue);
}
.main-header__icon.active,
.dropdown.show .main-header__icon {
  background-color: var(--soft-blue);
  color: var(--white);
}
.main-header__icon_chat.active .main-header__badge {
  display: none;
}

.main-header__badge.badge {
  display: flex;
  top: 1px;
  right: 0;
  transform: none;
}