#toast-container > .toast {
  display: flex;
  justify-content: flex-start;
  padding: 9px 50px 11px 84px;
  border-radius: 3px;
  opacity: 1;
  width: 100vw;
  max-width: 360px;
  min-height: 84px;
  box-sizing: border-box;
  box-shadow: none;
  &:hover {
    box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
    cursor: pointer;
  }
  &::before {
    position: absolute;
    top: 50%;
    left: var(--m-30);
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}

.toast__title {
  @include text-caption-l;
  color: var(--white);
}
.toast__message {
  @include text-caption-m;
  color: var(--white);
}

.toast-close-button {
  position: absolute;
  top: 50%;
  right: var(--p-30);
  transform: translateY(-50%);
  color: var(--white);
  opacity: 0.3;
}
.toast-close-button:hover,
.toast-close-button:focus {
  cursor: pointer;
  opacity: 0.7;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: var(--m-6);
  left: 50%;
  transform: translateX(-50%);
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/

}
#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0 var(--m-6);
  padding: var(--p-15) var(--p-15) var(--p-15) 50px;
  max-width: 360px;
  width: 100%;
  min-height: 84px;
  box-sizing: border-box;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}
#toast-container > .toast-info {
  &::before {
    content: '\E82E';
  }
}
#toast-container > .toast-error {
  &::before {
    content: '\E82D';
  }
}
#toast-container > .toast-success {
  &::before {
    content: '\E82C';
  }
}
#toast-container > .toast-warning {
  &::before {
    content: '\E81A';
  }
}
#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: var(--soft-green);
}
.toast-error {
  background-color: var(--coral);
}
.toast-info {
  background-color: var(--soft-blue);
}
.toast-warning {
  background-color: #ffa544;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}


