.choose-item {
  display: flex;
  align-items: center;
  padding: 10px var(--p-30);
  min-height: 75px;
  height: 70px;
  border-radius: 3px;
  background-color: #f7fcfc;
  &:hover {
    background-color: var(--pale);
  }
  &_selected {
    background-color: var(--ice);
  }
  &__btn {
    margin-left: auto;
    align-self: center;
  }
  &__checkbox-wrap {
    min-height: 30px;
    margin-bottom: 0;
    margin-left: auto;
    .checkbox + label {
      padding-left: var(--p-30);
      &::after {
       flex-shrink: 0;
       width: 30px;
       font-size: 30px;
     }
    }

  }


}