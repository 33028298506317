.select-template {
  position: relative;
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--p-6) var(--p-30);
    height: 45px;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    cursor: pointer;
    user-select: none;
    @include text-body-m;
    &_popup-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &_checked {
      background-color: var(--pale);
      border-color: var(--pale);
    }
  }

  &__input {
    margin-bottom: var(--m-15);
    padding: 5px var(--p-15);
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    border-radius: 3px;
    border: solid 1px var(--pale-grey);
    background-color: var(--white);
    @include text-caption-m;
    color: var(--dark);
    &::placeholder {
      @include text-caption-m;
      color: var(--light-blue-grey);
    }
  }

  &__label {
    margin-bottom: 5px;
    @include text-body-m;
    min-height: 20px;
    box-sizing: border-box;
    color: var(--dark);
    user-select: none;
    span {
      position: relative;
      z-index: 5;
      text-align: left;
    }
    &::after {
      top: 0 !important;
      transform: none !important;
      margin-top: 6px;
    }
  }

  &__icon-arrow {
    margin-left: var(--m-6);
    color: var(--light-blue-grey);
    transition: transform .2s ease-in-out;

    &_transform {
      transform: rotate(180deg);
    }
  }

  &__icon-close {
    padding: var(--p-6);
    margin-right: calc(var(--m-6) * -1);
    margin-left: var(--m-6);
    color: var(--beige);
  }

  &__popup {
    position: absolute;
    top: 100%;
    left: 0;
    padding: var(--p-15);
    width: 100%;
    max-height: 280px;
    box-sizing: border-box;
    overflow-y: auto;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
    border: solid 1px var(--pale-grey);
    border-top: none;
    background-color: var(--white);
    z-index: 5;
    &_simple {
      padding: 19px var(--p-30);
    }
  }
  .checkbox-wrap .checkbox:checked + label::after {
    z-index: 5;
  }
  &_simple {}
  .checkbox-wrap:hover label::before,
  .checkbox-wrap .checkbox:checked + label::before {
    content: '' !important;
    position: absolute;
    top: 50%;
    left: calc(var(--p-15) * -1);
    transform: translateY(-50%);
    width: calc(100% + var(--p-30));
    height: calc(100% + 10px);
    background-color: var(--ice);
  }
  .checkbox-wrap .checkbox + label::after {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: var(--light-blue-grey);
  }
  .checkbox-wrap .checkbox:checked + label::after {
    color: var(--soft-green);
  }
  &__label-simple {
    position: relative;
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
    &:hover::before,
    &_checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: calc(var(--p-30) * -1);
      transform: translateY(-50%);
      width: calc(100% + var(--p-60));
      height: 40px;
      background-color: var(--ice);
    }
    span {
      position: relative;
      z-index: 5;
    }
  }
}