.sample {
  &__title {
    color: #aaaebf;
    &_error {
      color: var(--coral);
      span {
        position: relative;
        padding-right: 20px;
        &:after {
          content: '*';
          position: absolute;
          top: -2px;
          right: 0;
        }
      }
    }
  }
  &__text-top {
    margin-bottom: 25px;
    color: #aaaebf;
    &_error {
      color: var(--coral);
    }
  }
  &__text {
    margin-bottom: var(--m-15);
    color: var(--dark);
    a,
    a:visited {
      color: var(--dark);
    }
  }
}