.responses {
  padding: 47px var(--p-60) var(--p-60) var(--p-60);
  &__title {
    margin-right: var(--m-15);
  }
  &__factory {
    padding: var(--p-15);
    background-color: #f2faf9;
    border-radius: 3px;
    &_archive {
      background-color: var(--pale-grey-two);
    }
    &:not(:last-of-type) {
      margin-bottom: var(--m-6);
    }
    &__title {
      display: inline-block;
      margin-bottom: 7px;
      line-height: 1;
    }
    &__city {
      margin-bottom: 1px;
    }
  }
}
.responses__top-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 21px;
}
.responses__help {

}