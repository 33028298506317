@keyframes btn-before {
  0% {height: 0}
  100% {height: 100%;}
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--m-60);
  &_right {
    justify-content: flex-end;
  }
  &_left {
    margin-top: 0;
    justify-content: flex-start;
  }
}
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none !important;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  background-color: transparent;
  &_big {
    display: flex;
    span {
      width: 100%;
    }
  }
  &_lower {
    text-transform: none !important;
  }
  &_font-norm {
    font-weight: normal !important;
  }

  span {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--p-60);
    box-sizing: border-box;
    min-height: 60px;
    height: 50px;
    border-radius: 30px;
    box-shadow: 0 10px 10px 0 rgba(var(--soft-green_rgb), .3);
    font-family: var(--font-family-sans-serif);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: 1.4px;
    white-space: nowrap;
    color: var(--white);
    .icon {
      margin-right: var(--m-15);
    }
  }

  &_small {
    span {
      @include text-caption-m;
      padding: 0 var(--p-30);
      min-height: 45px;
      height: 40px;
      border-radius: 22.5px;
      font-weight: normal;
      color: var(--white);
    }
  }

  &_white {
    span {
      font-weight: normal;
      color: var(--dark);
      background-color: var(--white);
      box-shadow: 0 10px 10px 0 rgba(192, 197, 217, 0.2);
    }
    &:hover span {
      color: var(--white);
    }
  }

  &:hover {
    &:before {
      content: none !important;
    }

    span {
      position: relative;
      box-shadow: 0 10px 10px 0 rgba(var(--soft-blue_rgb), .2);
      //cursor: no-drop;
      //cursor: url('../images/icons/tap.png'), pointer;
      cursor: pointer;

      background-color: var(--soft-blue);
      //cursor: url('../images/icons/cursor-tab.svg'), pointer !important;
    }
  }

  &:active span {
    box-shadow: none;
    background-color: #4172c7;
  }

  &:focus {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-radius: 3px;
      border: dashed 1px var(--soft-green);
      background-color: var(--ice);
    }
  }

  &_hover-anim {
    overflow: hidden;
    border-radius: 30px;

    &:hover {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--btn-hover-before);
        animation: btn-before var(--animation-btn-time) ease-in;
        animation-fill-mode: forwards;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: var(--btn-hover-after);
        animation: btn-before var(--animation-btn-time) ease-in;
        animation-delay: var(--animation-btn-time);
        animation-fill-mode: forwards;
      }
      span {
        background-color: var(--soft-green);
      }
    }
  }

  &_green {
    span {
      background-color: var(--soft-green);
    }
  }
  &_red {
    span {
      background-color: var(--coral);
    }
  }

  &_dark {
    span {
      background-color: var(--dark);
      box-shadow: none;
    }
  }
  &_coral {
    span {
      background-color: var(--coral);
      box-shadow: none;
    }
  }
  &_butterscotch {
    span {
      background-color: var(--butterscotch);
      box-shadow: none;
    }
  }

  &_outline span {
    border: solid 2px var(--light-blue-grey);
    background-color: var(--white);
    color: var(--light-blue-grey);
    box-shadow: none;

    &:hover {
      border-color: var(--soft-blue);
      color: var(--white);
    }

    &:active {
      background-color: #4172c7;
      border-color: #4172c7;
      color: var(--white);
    }

    &:focus {
      border: solid 2px var(--light-blue-grey);
      background-color: var(--white);
    }
  }

  &_disabled,
  &:disabled {
    pointer-events: none;
    &::after,
    &::before {
      content: none !important;
    }
  span {
    cursor: default !important;
    background-color: var(--pale-grey) !important;
    box-shadow: none !important;
    color: var(--white);

    &::after,
    &::before {
      content: none !important;
    }
  }
}
  span {
    position: relative;
    z-index: 2;
  }
}

.btn-hover-anim {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--p-60);
  box-sizing: border-box;
  min-height: 60px;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0 10px 10px 0 rgba(var(--soft-green_rgb), .3);
  background-color: var(--soft-green);
  outline: none;
  font-family: var(--font-family-sans-serif);
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: 1.4px;
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  overflow: hidden;
  &:hover {
    position: relative;
    box-shadow: 0 10px 10px 0 rgba(var(--soft-blue_rgb), .2);
    //cursor: no-drop;
    //cursor: url('../images/icons/tap.png'), pointer;
    cursor: pointer;

    background-color: var(--soft-green);
    //cursor: url('../images/icons/cursor-tab.svg'), pointer !important;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: var(--btn-hover-before);
      animation: btn-before  var(--animation-btn-time) ease-in;
      animation-fill-mode: forwards;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: var(--btn-hover-after);
      animation: btn-before var(--animation-btn-time) ease-in;
      animation-delay:  var(--animation-btn-time);
      animation-fill-mode: forwards;
    }
  }
  span {
    position: relative;
    z-index: 2;
  }
}

.btn-outline-square {
  padding: 2px var(--p-15) 1px var(--p-15);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 2px var(--light-blue-grey);
  background-color: var(--white);
  font-family: var(--font-family-source-sans-pro);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.2px;
  color: var(--light-blue-grey);
  text-transform: uppercase;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  i {
    margin-right: var(--m-6);
  }
  &:hover {
    box-shadow: 0 10px 10px 0 rgba(81, 142, 248, 0.2);
    background-color: var(--soft-blue);
    border-color: var(--soft-blue);
    color: var(--white);
  }
  &_dark {

  }
}
.btn-small {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  padding: 2px var(--p-15);
  min-height: 30px;
  height: 20px;
  border-radius: 3px;
  box-sizing: border-box;
  border: none;
  color: var(--white);
  cursor: pointer;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--font-family-sans-serif);
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: 1.2px;
  .icon {
    margin-right: var(--m-6);
  }
  &:hover {
    box-shadow: 0 10px 10px 0 rgba(81, 142, 248, 0.2);
    background-color: var(--soft-blue);
    border-color: var(--soft-blue);
    color: var(--white);
  }
  &_dark {
    background-color: var(--dark);
  }
  &_green {
    box-shadow: 0 10px 10px 0 rgba(95, 202, 114, 0.3);
    background-color: var(--soft-green);
  }
  &_butterscotch {
    box-shadow: 0 10px 10px 0 rgba(255, 197, 68, 0.3);
    background-color: var(--butterscotch);
  }
  &_coral {
    background-color: var(--coral);
  }
}

.edit-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
